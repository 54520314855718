import React, { useEffect, useRef, useState, useCallback } from "react";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import { POPUP } from "../../common/defines";
import common from "../../common/common";
import LoadingSpinner from "../common/LoadingSpinner";
import ReportGraphPieChart from "./ReportGraphPieChart";
import ReportGraphBarChart from "./ReportGraphBarChart";
import "./ReportGraphBased.css";
import { Row, Col } from "react-bootstrap";
import dgLogger from "../../common/dgLogger";
import { utility } from "@ocean-knight/shared";
import { useTranslation } from "react-i18next";
// import _ from "lodash";

const CHART_TYPE = utility.CHART_TYPE;

const CHART_ROW_MARGIN_CLASS = "mb-110px";
const FORM_ITEM_CHART_COL_CLASS = "col-md-6 col-12 py-4 report-form-item-chart";

export default function ReportGraphBased(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,

        isLoading: true,

        timeSeriesChart: undefined,
        groupChart: undefined,
        regionChart: undefined,
        reportCharts: [],

        allGroupSummary: false,
	allGroupSummaryRefreshKey: null,
    });
    const [formVersions, setFormVersions] = useState([]);
    const [formCharts, setFormCharts] = useState([]);
    const filterOption = props.filterOption;
    const basedOption = props.basedOption;
    const prevFilterOption = useRef({});
    const isMount = useIsMount();
    const lang = common.getLang();

    const getFilterOptions = useCallback((formVersions = [/*{groupId, projectReportFormId},...*/]) => {
        // update startDate
        let newStartDate = undefined;
        if (filterOption.startDate) {
            // update date into local one
            newStartDate = common.convertToIsoDate(filterOption.startDate);
        }

        // update endDate
        let newEndDate = undefined;
        if (filterOption.endDate) {
            // update date into local one
            newEndDate = common.convertToIsoDate(filterOption.endDate, 1);
        }

        // 선택된 항목들을 구분자(|)로 나누어 array로 생성
        const groupsArray = filterOption.groups ? filterOption.groups.split("|") : undefined;
        const projectsArray = filterOption.projects ? filterOption.projects.split("|") : undefined;
        const locationsArray = filterOption.locations ? filterOption.locations.split("|") : undefined;

        return {
            groups: groupsArray,
            projects: projectsArray,
            locations: locationsArray,
            startDate: newStartDate,
            endDate: newEndDate,
            registered_by: filterOption.name,
            keyword: filterOption.keyword,
            user_id: filterOption.myReport === "true" ? sessionStorage.getItem("_id") : undefined,
            formVersions: formVersions.map(x => ({groupId:x.groupId, formId: x.projectReportFormId}))
        };
    }, [filterOption]);

    /**
     * 특정 기간내 수집된 자료건수를 저장하고 있는 자료구조(TimeSeriesChart) 에 값이 없다면 (기간내 등록된 자료가 없는 경우)
     * Chart library 에 전달해야 할 자료구조를 맞추기 위해 임의로 (지정된 기간이 있다면, 기간만큼. 기간이 지정되어 있지 않다면 현재 날짜로부터 1년 전까지)
     * 카테고리 이름 (월/년) 을 채워주도록 합니다.
     * 
     * @param {*} timeSeriesChart 특정 기간내 수집된 자료 건수를 저장하고 있는 자료구조
     * @param {*} query 월별/년별 자료 요청 여부 (count_per_month / count_per_year)
     * @param {*} startDate 수집 자료건수 검색 조건인 기간의 시작날짜
     * @param {*} endDate 수집 자료건수 검색 조건인 기간의 종료 날짜
     */
    const updateTimeSeriesChartCategories = useCallback(async (timeSeriesChart, query, startDate = undefined, endDate = undefined) => {
        // 만약 얻어온 레포트 정보가 비어 있다면 (즉, 등록한 자료가 없는 경우)
        if (timeSeriesChart.categories.length == 0) {
            // 지정된 날짜가 있다면, 해당 날짜 사이의 기간으로 계산, 
            // 지정된 날짜가 없다면 현재 날짜를 기점으로 1년 전까지의 기간을 보여줌.
            const queryEndDate = endDate ? new Date(endDate) : new Date();
            const queryStartDate = startDate ? new Date(startDate) : new Date();
            if (!startDate && endDate == startDate) {
                queryStartDate.setFullYear(queryEndDate.getFullYear() - 1);
            }

            const start = { year: queryStartDate.getFullYear(), month: queryStartDate.getMonth() };
            const end = { year: queryEndDate.getFullYear(), month: queryEndDate.getMonth() };
            if (query == 'count_per_month') {
                timeSeriesChart.categories = [...Array(((end.year - start.year) * 12) - (start.month - end.month) + 1).keys()]
                    .map(category => new Date(start.year, start.month + category, 1))
                    .map(category => t("691", {'%1$d': category.getFullYear(), '%2$d': category.getMonth() + 1}));
            } else if (query == 'count_per_year') {
                timeSeriesChart.categories = [...Array(end.year - start.year + 1).keys()]
                    .map(category => new Date(start.year + category, 1, 1))
                    .map(category => category.getFullYear());
            }
        }
    }, [t]);

    const getFormVersions = useCallback(
        async (searchFilter) => {
            // console.log("enter getFormVersions");
            const formVersionsPerGroup = await Api.getReportFormVersions(searchFilter);
            // console.log("formVersionsPerGroup", formVersionsPerGroup);
            return formVersionsPerGroup;
        },
        []
    );

    /**
     * filter options 에 따라 출력에 필요한 정보들을 rest-api 를 통해 획득하여 state 에 저장합니다.
     * 정보 획득이 완료되면, loading state 를 종료합니다.
     */
    const getGraphBasedDataFromGroups = useCallback(async (searchFilter, formVersions, activeGrouplistAll) => {
        const timeSeriesChart = [];
        const reportCharts = [];

        const compareArrays = (a, b) => a.length === b.length && a.every((element, index) => element === b[index]);
        const allGroupSummary = searchFilter.groups ? compareArrays(searchFilter.groups.sort(), activeGrouplistAll.map(x => x._id).sort()) : true;


        // console.log("searchFilter", searchFilter);
        // console.log("formVersions", formVersions);
        const reportOverview = allGroupSummary ? undefined : await Api.getReportsGraph({ ...searchFilter, lang: lang });
        // console.log("reportOverview", reportOverview);


        // 전체 그룹의 경우, 단일 report 에 모든 그룹의 정보가 포함되어야 함.
        // 그외의 경우, 개별 그룹별로 report 가 만들어 져야 함.
        // 일반화 하기 위해, 전체 그룹의 경우는 item 이 한개인 array 로 처리

        const regionChartFuture = await Api.getReportsGraph({ ...searchFilter, field: "count_per_location", lang: lang });
        const regionChart = regionChartFuture.reports;

        const groupChartFuture = await Api.getReportsGraph({ ...searchFilter, field: "count_per_group", lang: lang });
        const groupChart = groupChartFuture.reports;
        // 자동 생성된 그룹인지 여부를 확인할수 있는 attribute 를 임의 추가
        groupChart.series = groupChart.series.map((v) => ({ ...v, built_in: activeGrouplistAll.find((x) => x._id == v._id)?.built_in }));

        for (const groupId of searchFilter.groups || []) {
            const group = activeGrouplistAll.find(x => x._id == groupId);
            const response = await Api.getReportForm({ report_form_id: group.report_form_id });
            group.report_form = response; // 새로운 key 를 많들어서 넣음
        }

        if (allGroupSummary) {
            // ✅ `allGroupSummary`가 true일 경우 한 번만 API 호출
            const response = await Api.getReportsGraph({
                ...searchFilter,
                field: "count_per_month", // 그래프로 표시할 필드
                lang: lang,
            });

            const group = searchFilter.groups && activeGrouplistAll.find((x) => x._id == searchFilter.groups[0]);
            response.reports.group = group;

            updateTimeSeriesChartCategories(response.reports, "count_per_month", searchFilter.startDate, searchFilter.endDate);

            // 수집자료 건수는, 그룹별로 reports 를 따로 수집. (전체 그룹의 경우, 단일 reports 에 모든 그룹 정보를 수집)
            timeSeriesChart.push(response.reports);

            // 그룹 전체 보기에서는, 그룹별 수집자료건수가 아닌, 합산된 수집 자료건수로 표시
            timeSeriesChart[0].series = [{ _id: null, name: t("404"), data: sum(timeSeriesChart[0].series.map((x) => x?.data)) }];
            regionChart.series = [{ _id: null, name: t("404"), data: sum(regionChart.series.map((x) => x?.data)) }];
        } else {
            // ✅ `payload.groups` 배열을 순차 실행
            const responses = await Promise.all(searchFilter.groups.map(v => Api.getReportsGraph({
                ...searchFilter,
                groups: [v],
                field: "count_per_month",
                lang: lang,
            })));
            for (const [index, response] of responses.entries()) {
                const group = searchFilter.groups && activeGrouplistAll.find((x) => x._id == searchFilter.groups[index]);
                response.reports.group = group;

                updateTimeSeriesChartCategories(response.reports, "count_per_month", searchFilter.startDate, searchFilter.endDate);

                // 수집자료 건수는, 그룹별로 reports 를 따로 수집. (전체 그룹의 경우, 단일 reports 에 모든 그룹 정보를 수집)
                timeSeriesChart.push(response.reports);
            }

            // console.log("timeSeriesChart", timeSeriesChart);
        }

        if (reportOverview) {
            const chartingReports = reportOverview.reports.filter((x) => x.chart?.type !== "none");
            // console.log("chartingReports", chartingReports);
            // console.log("chartingReports uniq", _.uniqBy(chartingReports, "itemId"));
            const responses = await Promise.all(chartingReports.map(v => Api.getReportsGraph({
                ...searchFilter,
                field: v.itemId,
                lang: lang,
            })));
            for (const response of responses) {
                // 얻어온 report 는 대략적인 레포트 정보가 들어 있는 reportOverview 에 추가로 삽입하도록 처리
                // (reportOverview 에 있는 그룹 이름이나, 프로젝트 정보등이 필요한 경우가 발생할 수 있음)
                const reportItem = reportOverview.reports.find((x) => x.itemId == response.reports.series[0]._id);
                reportItem.categories = response.reports.categories;
                reportItem.series = response.reports.series;
                reportCharts.push(reportItem);
            }
        }

        // vendor-ui-2 의 경우, 쓰레기 양 평가 (합) 의 reports 를 출력할수 있도록 기존값(reportOverView.reports item) 의 자료구조를 overwrite
        const vendorUI2ReportOverview = reportOverview?.reports.find(x => x.itemType == 'vendor-ui-2');
        if (vendorUI2ReportOverview) {
            // 페이로드로 전달된 프로젝트(들) 의 쓰레기 양평가 합을 모두 더한 결과를 반환받음.
            const trashAmountPerLoc = await Api.getReportsGraph({ ...searchFilter, field: "sum_trash_amount_per_location", lang: lang });
            vendorUI2ReportOverview.categories = trashAmountPerLoc.reports.categories;
            vendorUI2ReportOverview.series = trashAmountPerLoc.reports.series;
        }

        if (!allGroupSummary) {
            // 그룹에 따른, form revision 정보 및, 통계(차트) 정보를 통합 자료구조(list of dictionary) 로 변환.
            const formChartsPerGroup = timeSeriesChart.map((chartInfo) => {
                const groupName = chartInfo.group.name;
                const groupId = chartInfo.group._id;
                const items = reportCharts.filter((x) => x.name == groupName);
                const groupInfo = activeGrouplistAll.find((group) => group._id == groupId);
                // console.log("chartInfo", chartInfo);

                const sortedItems = groupInfo.report_form.report_form_item_ids.reduce((acc, cur) => {
                    const item = items.find((item) => item.itemId == cur);
                    item && acc.push(item);
                    return acc;
                }, []);

                const formVersionInfo = formVersions.find((x) => x.groupId == groupId);
                const formRevisions = formVersionInfo?.reportFormRevisions?.sort((a, b) => new Date(b.revision) - new Date(a.revision)) || [];
                const selectedFormId = formVersionInfo?.projectReportFormId;

                const value = {
                    groupId: chartInfo.group._id,
                    groupName,
                    builtInGroup: chartInfo.group.built_in, // 자동 생성된 그룹 여부
                    formRevisions,
                    selectedFormId,
                    items,
                    sortedItems, // 그룹에 등록된 report form item 순서대로 출력되도록 정렬
                };

                // console.log("formChart", value);

                return value;
            });

            // console.log("formChartsPerGroup", formChartsPerGroup);
            setFormCharts(formChartsPerGroup);
        }

        setState((prev) => ({ ...prev, 
		groupChart, 
		timeSeriesChart, 
		regionChart, 
		reportCharts, 
		allGroupSummary, 
		isLoading: false,
		allGroupSummaryRefreshKey:JSON.stringify(props.filterOption),
	}));
    }, [
        updateTimeSeriesChartCategories,
        props.filterOption, lang, t
    ]);

    const getGraphBasedDataFromGroup = useCallback(async (searchFilter, activeGrouplistAll) => {
            const timeSeriesChart = [];
            const reportCharts = [];
            const selectedGroupList = activeGrouplistAll.filter((x) => searchFilter.groups.find((group) => group == x._id));

            const filteredFormChartsItems = [];
            const filteredFormChartsSnapshot = formCharts.map((x, index) => {
                if (searchFilter.groups.find((group) => group == x.groupId)) {
                    filteredFormChartsItems.push({ index, value: x });
                    return undefined;
                } else return x;
            });
            // console.log("filteredFormChartsSnapshot", filteredFormChartsSnapshot);

            const filteredTimeSeriesChartItems = [];
            const filteredTimeSeriesCharSnapshot = state.timeSeriesChart.map((x, index) => {
                if (searchFilter.groups.find((group) => group == x.group._id)) {
                    filteredTimeSeriesChartItems.push({ index, value: x });
                    return undefined;
                } else return x;
            });
            // console.log("filteredTimeSeriesChartsSnapshot", filteredTimeSeriesCharSnapshot);

            const filteredRegionChartSeries = [];
            const filteredRegionChartSeriesSnapshot = state.regionChart.series.map((x, index) => {
                if (searchFilter.groups.find((group) => group == x._id)) {
                    filteredRegionChartSeries.push({ index, value: x });
                    return undefined;
                } else return x;
            });
            // console.log("filteredRegionChartSeriesSnapshot", filteredRegionChartSeriesSnapshot);

            // console.log("activeGrouplistAll", activeGrouplistAll);

            const reportOverview = await Api.getReportsGraph({ ...searchFilter, lang: lang });

            // 전체 그룹의 경우, 단일 report 에 모든 그룹의 정보가 포함되어야 함.
            // 그외의 경우, 개별 그룹별로 report 가 만들어 져야 함.
            // 일반화 하기 위해, 전체 그룹의 경우는 item 이 한개인 array 로 처리

            const regionChartFuture = await Api.getReportsGraph({ ...searchFilter, field: "count_per_location", lang: lang });
            const regionChart = regionChartFuture.reports;
            // console.log("regionChart", regionChart);

            const groupChartFuture = await Api.getReportsGraph({ ...searchFilter, field: "count_per_group", lang: lang });
            const groupChart = groupChartFuture.reports;
            // 자동 생성된 그룹인지 여부를 확인할수 있는 attribute 를 임의 추가
            groupChart.series = groupChart.series.map((v) => ({ ...v, built_in: selectedGroupList.find((x) => x._id == v._id)?.built_in }));

            for (const groupId of searchFilter.groups || []) {
                const group = activeGrouplistAll.find((x) => x._id == groupId);
                const reportFormId = searchFilter.formVersions.find((x) => x.groupId == group._id)?.formId;
                console.assert(reportFormId != null);
                const response = await Api.getReportForm({ report_form_id: reportFormId });
                group.report_form = response; // 새로운 key 를 많들어서 넣음
            }

            // ✅ `payload.groups` 배열을 순차 실행
            const responses = await Promise.all(
                searchFilter.groups.map((v) =>
                    Api.getReportsGraph({
                        ...searchFilter,
                        groups: [v],
                        field: "count_per_month",
                        lang: lang,
                    })
                )
            );
            for (const [index, response] of responses.entries()) {
                const group = searchFilter.groups && selectedGroupList.find((x) => x._id == searchFilter.groups[index]);
                response.reports.group = group;

                updateTimeSeriesChartCategories(response.reports, "count_per_month", searchFilter.startDate, searchFilter.endDate);

                // console.log("count_per_month report", response.reports);

                // 수집자료 건수는, 그룹별로 reports 를 따로 수집. (전체 그룹의 경우, 단일 reports 에 모든 그룹 정보를 수집)
                timeSeriesChart.push(response.reports);
            }

            if (reportOverview) {
                const chartingReports = reportOverview.reports.filter((x) => x.chart?.type !== "none");
                const responses = await Promise.all(
                    chartingReports.map((v) =>
                        Api.getReportsGraph({
                            ...searchFilter,
                            field: v.itemId,
                            lang: lang,
                        })
                    )
                );
                for (const response of responses) {
                    // 얻어온 report 는 대략적인 레포트 정보가 들어 있는 reportOverview 에 추가로 삽입하도록 처리
                    // (reportOverview 에 있는 그룹 이름이나, 프로젝트 정보등이 필요한 경우가 발생할 수 있음)
                    const reportItem = reportOverview.reports.find((x) => x.itemId == response.reports.series[0]._id);
                    reportItem.categories = response.reports.categories;
                    reportItem.series = response.reports.series;
                    reportCharts.push(reportItem);
                }
            }

            // vendor-ui-2 의 경우, 쓰레기 양 평가 (합) 의 reports 를 출력할수 있도록 기존값(reportOverView.reports item) 의 자료구조를 overwrite
            const vendorUI2ReportOverview = reportOverview?.reports.find((x) => x.itemType == "vendor-ui-2");
            if (vendorUI2ReportOverview) {
                // 페이로드로 전달된 프로젝트(들) 의 쓰레기 양평가 합을 모두 더한 결과를 반환받음.
                const trashAmountPerLoc = await Api.getReportsGraph({ ...searchFilter, field: "sum_trash_amount_per_location", lang: lang });
                vendorUI2ReportOverview.categories = trashAmountPerLoc.reports.categories;
                vendorUI2ReportOverview.series = trashAmountPerLoc.reports.series;
            }

            // 그룹에 따른, form revision 정보 및, 통계(차트) 정보를 통합 자료구조(list of dictionary) 로 변환.
            const formChartsPerGroup = timeSeriesChart.map((chartInfo) => {
                const groupName = chartInfo.group.name;
                const groupId = chartInfo.group._id;
                const items = reportCharts.filter((x) => x.name == groupName);
                const groupInfo = selectedGroupList.find((group) => group.name == groupName);
                // console.log("chartInfo", chartInfo);
                // console.log("items", items);

                const sortedItems = groupInfo.report_form.report_form_item_ids.reduce((acc, cur) => {
                    const item = items.find((item) => item.itemId == cur);
                    item && acc.push(item);
                    return acc;
                }, []);

                const formVersionInfo = formVersions.find((x) => x.groupId == groupId);
                const formRevisions = formVersionInfo?.reportFormRevisions?.sort((a, b) => new Date(b.revision) - new Date(a.revision)) || [];
                const selectedFormId = searchFilter.formVersions.find((x) => x.groupId == groupId).formId;

                return {
                    groupId: chartInfo.group._id,
                    groupName,
                    builtInGroup: chartInfo.group.built_in, // 자동 생성된 그룹 여부
                    formRevisions,
                    selectedFormId,
                    items,
                    sortedItems, // 그룹에 등록된 report form item 순서대로 출력되도록 정렬
                };
            });

            filteredTimeSeriesChartItems.forEach(({ index, value }) => (filteredTimeSeriesCharSnapshot[index] = timeSeriesChart.find((x) => x.group._id == value.group._id)));
            // console.log("state.timeSeriesChart", state.timeSeriesChart);
            // console.log("curnt.timeSeriesChart", timeSeriesChart);
            // console.log("new.timeSeriesChart", filteredTimeSeriesCharSnapshot);

            // 기존과 동일한 항목이라면, replace
            filteredRegionChartSeries.forEach(({ index, value }) => (filteredRegionChartSeriesSnapshot[index] = regionChart.series.find((x) => x._id == value._id)));
            // 새로운 항목이라면, append
            regionChart.series.forEach((x) => {
                if (![...state.regionChart.series, ...filteredRegionChartSeriesSnapshot].some(s => s?._id === x._id)) {
                    filteredRegionChartSeriesSnapshot.push(x);
                }
            });
            // console.log("state.regionChart", state.regionChart);
            // console.log("curnt.regionChart", regionChart);
            // console.log("new.regionChart", filteredRegionChartSeries);

            // console.log("state.reportCharts", state.reportCharts);
            // console.log("curnt.reportCharts", reportCharts);

            // Note. groupChart 는 renderAllGroupSummary 에서만 사용되고 있으므로, 현재는 불필요함, 추후 필요시 코드 작성.
            setState({
                ...state,
                timeSeriesChart: filteredTimeSeriesCharSnapshot.filter(x => x),
                regionChart: {
                    ...state.regionChart,
                    series: filteredRegionChartSeriesSnapshot.filter(x => x),
                },
                reportCharts,
                isLoading: false,
            });

            // console.log("formChartsPerGroup", formChartsPerGroup);
            filteredFormChartsItems.forEach(({ index, value }) => (filteredFormChartsSnapshot[index] = formChartsPerGroup.find((x) => x.groupId == value.groupId)));
            // console.log("filteredFormChartsSnapshot", filteredFormChartsSnapshot);
            setFormCharts(filteredFormChartsSnapshot.filter((x) => x));
        },
        [updateTimeSeriesChartCategories, formCharts, formVersions, lang, state]
    );

    // filterOption이 바뀌었을 때 현재 filterOption에 따라 graph에서 사용 할 data 요청
    useEffect(() => {
        if (!isMount?.current) return;
        if (JSON.stringify(prevFilterOption.current) === JSON.stringify(filterOption)) return;
        if (props.groupList.length == 0) return;
        // if (formVersions.length > 0) return;

        setState((prev) => ({ ...prev, isLoading: true }));
        prevFilterOption.current = filterOption;

        (async () => {
            let searchFilter = getFilterOptions();
            if (!searchFilter.groups) searchFilter.groups = props.groupList.map(x => x._id);
            // console.log("props.groupList", props.groupList);
            // console.log("searchFilter -1", searchFilter);

            const formVersions = await getFormVersions(searchFilter);
            // console.log("formVersions 0", formVersions);

            searchFilter = getFilterOptions(formVersions);
            // console.log("searchFilter 0", searchFilter);

            await getGraphBasedDataFromGroups(searchFilter, formVersions, props.groupList);

            setFormVersions(formVersions);
        })();
    }, [
        props.groupList,
        getGraphBasedDataFromGroups, getFilterOptions, getFormVersions,
        filterOption, isMount,  formVersions
    ]);

    // 다수의 동일 length 의 array 를 각 index 의 item 을 sum 하여 새로운 array 를 반환
    const sum = (matrix) => matrix.reduce((acc, array) => acc.map((sum, i) => sum + (array[i] || 0)), new Array(matrix[0]?.length).fill(0));

    const popups = () => {
        // if (state.popup === POPUP.Request) return popupDownloadRequest();
    };

    // 공통 차트
    const renderCommonChart = (selectedFormId = undefined, groupFilter = undefined, legendVisible = true) => {
        const chart = groupFilter ? state.timeSeriesChart?.find(x => groupFilter.name == x.group.name) : state.timeSeriesChart?.[0];
        if (!chart) return <></>;

        // console.log("form id", selectedFormId);
        // console.log("chart", chart);
        // console.log("groupFilter", groupFilter);
        // console.log("state.regionChart.series", state.regionChart.series);

        return (<React.Fragment key={selectedFormId}>
            <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                <ReportGraphBarChart
                    categories={chart.categories}
                    series={chart.series}
                    onChangeScale={async (scale) => { // possible scale is 'month', 'year'
                        const searchFilter = getFilterOptions(selectedFormId && [{groupId: groupFilter._id, projectReportFormId: selectedFormId}]);
                        searchFilter.field = scale === 'month' ? 'count_per_month' : 'count_per_year'; // 그래프로 표시할 필드
                        searchFilter.lang = lang;

                        if (groupFilter) searchFilter.groups = [groupFilter._id];
                        const timeSeriesChart = (await Api.getReportsGraph(searchFilter)).reports;

                        updateTimeSeriesChartCategories(timeSeriesChart, searchFilter.field, searchFilter.startDate, searchFilter.endDate);

                        if (state.allGroupSummary) {
                            timeSeriesChart.series = [{ _id: null, name: t("404"), data: sum(timeSeriesChart.series.map(x => x.data)) }];
                        }

                        return timeSeriesChart;
                    }}
                    title={t("692")}
                    // yAxisTitle={"건수"}
                    // xAxisTitle={"기간"}
                    legendVisible={legendVisible}
                    showScaleDropbox={true}
                    vertical={true}
                />
            </Col>
            <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                <ReportGraphBarChart
                    categories={state.regionChart.categories}
                    series={state.regionChart.series.filter(x => groupFilter ? groupFilter.name == x.name[0] : true)}
                    title={t("693")}
                    // yAxisTitle={"건수"}
                    // xAxisTitle={"지역"}
                    legendVisible={false}
                    colorByCategories={false}
                    vertical={true}
                    interval={1}
                />
            </Col>
        </React.Fragment>);
    };

    const renderAllGroupSummary = () => {
        // 전체 그룹을 출력하는 상황이 아니라면, 아무것도 출력하지 않습니다.
        if (!state.allGroupSummary) return <></>;

        return (<React.Fragment key={state.allGroupSummaryRefreshKey}>
            <Row className="gx-0">
                <Col className="col-md-12 col-sm-12 c-000 notosanskr-500 font-size-21 bg-e8ebf0 mb-4 p-4" style={{ borderRadius: "5px" }} >
                    {t("39")}
                </Col>
            </Row>
            <Row className={`gx-0 ${CHART_ROW_MARGIN_CLASS}`}>
                {renderCommonChart(undefined, undefined, false)}
                <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                    <ReportGraphPieChart
                        categories={state.groupChart.series.map(x => common.i18nGroupName(x.name[0]))}
                        series={[{ label: t("404"), data: state.groupChart.series.map((series) => series.data) }]}
                        title={t("694")}
                        doughnut={false}
                        legendVisible={true}
                    />
                </Col>
            </Row>
        </React.Fragment>);
    };

    const renderFormCharts = () => {
        if (!state.timeSeriesChart) {
            // console.log("has no time series chart");
            return <></>;
        }

        return (
            <React.Fragment>
                {formCharts.map((groupChart) => (
                    groupChart.selectedFormId ? <React.Fragment key={groupChart.selectedFormId}>
                        <Row className="gx-0 c-000 notosanskr-500 font-size-21 bg-e8ebf0 mb-4 p-4 align-items-center" style={{ borderRadius: "5px" }}>
                            <Col className="col-12 col-md-auto col-lg-auto">{common.i18nGroupName(groupChart.groupName)}</Col>
                            { groupChart.formRevisions?.length > 0 && (
                                <Col className="col-md col-lg col-sm-12 text-md-end form-verion-item">
                                    {t("112")}
                                    <select
                                        className="mt-20px:sm ml-20px ps-4 pr-42px form-version-select notosanskr-400 font-size-14"
                                        onChange={(e) => {
                                            // console.log("selected :", e.target.textContent, " form id :", e.target.value);
                                            const searchFilter = getFilterOptions([{groupId: groupChart.groupId, projectReportFormId: e.target.value}]);
                                            searchFilter.groups = [groupChart.groupId];
                                            getGraphBasedDataFromGroup(searchFilter, props.groupList);
                                        }}
                                        defaultValue={groupChart.selectedFormId}
                                    >
                                        {groupChart.formRevisions.map((revision) => (
                                            <option key={revision._id} value={revision._id}>
                                                {revision.revision}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                            )}
                        </Row>
                        <Row className={`${CHART_ROW_MARGIN_CLASS}`}>
                            { renderCommonChart(
                                groupChart.selectedFormId, // key
                                props.groupList.find((x) => x.name == groupChart.groupName),
                                false
                            )}
                            {groupChart.sortedItems?.map((formItem) => {
                                // built-in 그룹 (바다기사단) 의 경우, common chart 만 출력
                                if (groupChart.builtInGroup) {
                                    dgLogger.debug(`${groupChart.groupName} is built-in group. do not show form charts`)();
                                    return <Col key={formItem.itemId} />;
                                }

                                if (!formItem.chart?.type || formItem.chart?.type == CHART_TYPE.None) {
                                    dgLogger.info(`${formItem.itemName} in ${groupChart.groupName} is set to hidden`)();
                                    return <Col key={formItem.itemId}></Col>;
                                }

                                const categories = formItem.categories.map((v) => (["기타", "기타:", "기타 :"].includes(v.trim()) ? t("59") : v));
                                switch (formItem.chart.type) {
                                    case CHART_TYPE.Pie:
                                        return (
                                            <Col key={formItem.itemId} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                                <ReportGraphPieChart
                                                    categories={categories}
                                                    series={formItem.series.map((x) => ({ ...x, name: groupChart.groupName }))}
                                                    title={formItem.itemName}
                                                    doughnut={false}
                                                />
                                            </Col>
                                        );
                                    case CHART_TYPE.Doughnut:
                                        return (
                                            <Col key={formItem.itemId} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                                <ReportGraphPieChart
                                                    categories={categories}
                                                    series={formItem.series.map((x) => ({ ...x, name: groupChart.groupName }))}
                                                    title={formItem.itemName}
                                                    doughnut={true}
                                                />
                                            </Col>
                                        );
                                    case CHART_TYPE.Vertical:
                                        return (
                                            <Col key={formItem.itemId} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                                <ReportGraphBarChart
                                                    categories={categories}
                                                    series={formItem.series.map((x) => ({ ...x, name: groupChart.groupName }))}
                                                    title={formItem.itemName}
                                                    showScaleDropbox={false}
                                                    legendVisible={false}
                                                    vertical={true}
                                                    // item type 이 vendor-ui-2 인 경우, 지역을 출력해야 하므로, internal 을 1 로 설정하도록 함.
                                                    // see: renderCommonChart 의 지역별 수집 자료 건수 Chart attributes
                                                    interval={formItem.itemType === "vendor-ui-2" && 1}
                                                />
                                            </Col>
                                        );
                                    case CHART_TYPE.Horizontal:
                                        return (
                                            <Col key={formItem.itemId} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                                <ReportGraphBarChart
                                                    categories={categories}
                                                    series={formItem.series.map((x) => ({ ...x, name: groupChart.groupName }))}
                                                    title={formItem.itemName}
                                                    legendVisible={false}
                                                />
                                            </Col>
                                        );
                                    default:
                                        return <Col key={formItem.itemId}></Col>;
                                }
                            })}
                        </Row>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Row className="gx-0 c-000 notosanskr-500 font-size-21 bg-e8ebf0 mb-4 p-4 align-items-center" style={{ borderRadius: "5px" }}>
                            <Col className="col-12 col-md-auto col-lg-auto">{common.i18nGroupName(groupChart.groupName)}</Col>
                        </Row>
                        <Row className={`${CHART_ROW_MARGIN_CLASS}`}>
                            <Col id="empty-report-text" className="notosanskr-500 font-size-40" style={{ textAlign: "center" }}>
                                {t("36")}
                            </Col>
                        </Row>
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    };

    return (
        <div className="report-graph-based">
            <LoadingSpinner isOpen={state.isLoading} />
            {popups()}
            {state.allGroupSummary ? renderAllGroupSummary() : renderFormCharts()}
        </div>
    );
}
