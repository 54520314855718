import "./NoticeListView.css";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useIsMount, useQueryParams } from "../../../common/customHook";
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { POPUP as GeneralPopup } from "../../../common/defines";
import Api from "../../../Api";
import common from "../../../common/common";
import dgLogger from "../../../common/dgLogger";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { IsMobile, IsPC } from "../../common/MediaQuery";
import Pagination from "../../common/Pagination";
import { Col, Row } from "react-bootstrap";
import { NOTICE_FILTER_TYPE } from "@ocean-knight/shared/src/utility";
import { InputText } from "../../Input";
import { AppContext } from "../../../AppContext";
import { utility } from "@ocean-knight/shared";
import _ from "lodash";

const POPUP = {
    ...GeneralPopup,
};

export default function NoticeListView(props) {
    const { _no = 1 } = useParams() ?? {};
    const { type: filterType, keyword: filterKeyword } = useQueryParams();
    const context = useContext(AppContext);
    const { t } = useTranslation();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const isMobile = IsMobile();
    const isPc = IsPC();
    const paginationOption = useRef({ itemsCountPerPage: 10, pageRangeDisplayed: isMobile ? 5 : 10, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const [user, setUser] = useState({});
    const [groups, setGroups] = useState([]);
    const [filter, setFilter] = useState({
        type: filterType || NOTICE_FILTER_TYPE.TITLE_W_CONTENT,
        keyword: filterKeyword || "",
    });
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        notices: null, // 공지사항 목록
    });

    useEffect(() => {
        document.getElementById("App").className = "more";
        const fetchNotices = async (params) => {
            try {
                const payload = await Api.fetchNotices(params);
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                payload.results = payload.results.map((result) => ({
                    ...result,
                    createdAt: new Date(result.createdAt).toLocaleString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour12: false,
                    }),
                    isAllGroups: _.isEqualWith(
                        _.sortBy(result.allGroups || [], ["groupId"]),
                        _.sortBy(result.groups || [], ["groupId"])
                    )
                }));
                setState((prev) => ({ ...prev, notices: payload.results || [] }));
            } catch (e) {
                dgLogger.error(e)();
                paginationOption.current.totalItemsCount = 0;
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        fetchNotices({
            page: _no,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            filter: filterKeyword && filterType,
            keyword: filterKeyword,
        });
    }, [isMount, _no, filterType, filterKeyword]);

    useEffect(() => {
        const getUserInfo = async () => {
            if (context.loggedIn) {
                const user = await Api.getCurrentUserInfo({ optPermissions: true });
                setUser(user);
            }
        };
        getUserInfo();
    }, [context.loggedIn]);

    useEffect(() => {
        const getGroupList = async () => {
            try {
                const payload = await Api.getActiveGroupListAll();
                setGroups(payload);
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        getGroupList();
    }, []);

    const popups = () => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return <GeneralErrorWindow message={state.popupTarget} onClick={() => navigate("/notice")} />;
            case POPUP.None:
                break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    };

    const pageChange = useCallback(
        (page) => {
            const type = filterType ? `?type=${filterType}` : "";
            const keyword = filterKeyword ? `&keyword=${filterKeyword}` : "";
            navigate(`/notice/page/${page}${type}${keyword}`);
        },
        [navigate, filterType, filterKeyword]
    );

    const searchClick = useCallback(() => {
        // 신규 검색시, 검색된 결과의 첫 페이지로 이동
        navigate(`/notice/page/1?type=${filter.type}&keyword=${filter.keyword}`);
    }, [navigate, filter.type, filter.keyword]);

    const isModifiable = useCallback(() => {
        // 공지를 등록한 사용자 이거나, 관리자일 경우
        if (!user.optPermissions) return false;
        if (!user._id) return false;

        const maxPermission = user.optPermissions.reduce((max, val) => (max.grade > val.grade ? max : val));
        const hasManagePermissions = groups.some((group) => {
            const perm = user.optPermissions.find((permission) => permission.group === group._id);
            return utility.hasGroupManagerPermission(perm?.grade);
        });
        return hasManagePermissions || utility.hasSiteAdminPermission(maxPermission?.grade);
    }, [user.optPermissions, user._id, groups]);

    return (
        <div id="notice-list-view">
            {popups()}
            <Row className="header align-items-center mb-25px mb-35px:md pt-unset:md pt-30px:sm">
                <Col>
                    <label className="notosanskr-40b notosanskr-26b:md notosanskr-20b:sm letter-spacing--1px letter-spacing--0.5px:sm">{t("916")}</label>
                </Col>
                {isModifiable() && (
                    <Col className="col-auto">
                        <button className="notosanskr-16 notosanskr-13:sm letter-spacing--0.8px letter-spacing--0.65px:sm c-white" onClick={() => navigate("/notice/new")}>
                            {t("928")}
                        </button>
                    </Col>
                )}
            </Row>
            {isPc ? (
                <>
                    <Row className="mb-50px">
                        <Col className="col-12">
                            <table className="result nanumsquare-20 text-center">
                                <thead className="c-4270e0">
                                    <tr>
                                        <td className="notosanskr-20 minw-80px">No.</td>
                                        <td className="minw-200px">{t("38")}</td>
                                        <td className="w-100% text-center">{t("275")}</td>
                                        <td className="minw-200px">{t("930")}</td>
                                        <td className="minw-140px">{t("931")}</td>
                                        <td className="minw-80px">{t("919")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.notices && (state.notices.length > 0 ? state.notices.map((notice) => (
                                        <tr key={notice._id} className={`mouse-over`} onClick={() => navigate(`/notice/${notice._id}`)}>
                                            <td className="notosanskr-20 letter-spacing--0.5px minw-80px">
                                                {common.isCurrentTimeBetween(notice.pinnedStartAt, notice.pinnedEndAt) ? t("932") : notice.no}
                                            </td>
                                            <td className="minw-200px">
                                                {notice.isAllGroups ?
                                                    t("404")
                                                    :
                                                    notice.groups.map((group) => (
                                                        <div key={group.groupId}>{group.groupName}</div>
                                                    ))
                                                }
                                            </td>
                                            <td className="title w-100% text-break">{notice.title}</td>
                                            <td className="minw-200px text-break">{notice.authorName}</td>
                                            <td className="minw-140px">{notice.createdAt}</td>
                                            <td className="minw-80px">{notice.views}</td>
                                        </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={6} className="notosanskr-20 letter-spacing--0.5px w-80px">
                                                {t("940")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {state.notices?.length > 0 && (
                        <Row className="mb-50px">
                            <Col className="col-12">
                                <Pagination
                                    ref={pageChange}
                                    page={_no}
                                    marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="col-12 d-flex justify-content-center align-items-center">
                            <select
                                required
                                className="notice-filter-type notosanskr-14"
                                id="notice-filter-type"
                                name="notice-filter-type"
                                onChange={(e) => {
                                    setFilter({ ...filter, type: e.target.value });
                                }}
                                defaultValue={filterType}
                                disabled={false}
                            >
                                <option value={NOTICE_FILTER_TYPE.TITLE_W_CONTENT}>{t("933")}</option>
                                <option value={NOTICE_FILTER_TYPE.TITLE}>{t("934")}</option>
                                <option value={NOTICE_FILTER_TYPE.AUTHOR}>{t("930")}</option>
                            </select>
                            <div className="d-flex">
                                <InputText
                                    className="ml-20px keyword notosanskr-14 w-590px"
                                    id="keyword"
                                    onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                    placeholder={t("935")}
                                    defaultValue={filterKeyword}
                                    onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
                                />
                                <button className="search-magnifier" onClick={() => searchClick()}></button>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                // tablet && mobile
                <>
                    <Row>
                        <Col className="col d-flex pb-12px pb-35px:md border-bottom">
                            <InputText
                                className="keyword notosanskr-14:md notosanskr-13:sm letter-spacing--1.05px:md letter-spacing--0.65px:sm w-100"
                                id="keyword"
                                onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                placeholder={t("935")}
                                defaultValue={filterKeyword}
                                onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
                            />
                            <button className="search-magnifier" onClick={() => searchClick()}></button>
                        </Col>
                    </Row>
                    {state.notices && (state.notices?.length ? state.notices?.map((notice) => (
                        <Row
                            key={notice._id}
                            className="pt-15px:md pt-10px:sm pb-15px:md pb-10px:sm pl-15px pr-15px border-bottom align-items-center mouse-over"
                            onClick={() => navigate(`/notice/${notice._id}`)}
                        >
                            {common.isCurrentTimeBetween(notice.pinnedStartAt, notice.pinnedEndAt) && (
                                <Col className="col-auto pr-10px:sm pr-35px:md">
                                    <span className="pinned nanumsquare-13b:sm nanumsquareeb-15:md letter-spacing--0.65px:sm letter-spacing--0.75px:md">{t("932")}</span>
                                </Col>
                            )}
                            <Col className="">
                                <div className="nanumsquare-14:sm nanumsquare-18:md letter-spacing--0.35px:sm letter-spacing--0.45px:md text-break mb-7px">{notice.title}</div>
                                <div className="d-flex nanumsquare-10:sm nanumsquare-14:md letter-spacing--0.25px:sm letter-spacing--0.35px:md">
                                    <span className="author-name text-break">{notice.authorName}</span>
                                    <span className="ml-12px:md ml-10px:sm text-break">{notice.createdAt}</span>
                                </div>
                            </Col>
                        </Row>
                        ))
                        :
                        <Row
                            className="pt-15px:md pt-10px:sm pb-15px:md pb-10px:sm pl-15px pr-15px border-bottom align-items-center mouse-over"
                        >
                            <Col className="col pr-10px:sm pr-35px:md text-center">
                                <span className="nanumsquare-14:sm nanumsquare-18:md letter-spacing--0.35px:sm letter-spacing--0.45px:md text-break mb-7px">{t("940")}</span>
                            </Col>
                        </Row>
                    )}
                    {state.notices?.length > 0 && (
                        <Row className="mb-88px:sm mt-20px:sm mt-25px:md">
                            <Col className="col-12">
                                <Pagination
                                    ref={pageChange}
                                    page={_no}
                                    marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
}
