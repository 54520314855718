import { utility, strings } from "@ocean-knight/shared";
import Api from "../Api";
import * as Config from "../Components/common/MapConfig";
import AWS  from "aws-sdk";
import dgLogger from "./dgLogger";
import { VIEW } from "./defines";
import i18n from 'i18next';
import { useCallback, useRef } from "react";

/**
 * 현재 사용자가 group manager 권한을 가지고 있는지 확인합니다.
 * @returns 현재 사용자가 group manager 권한을 가지고 있는지 여부를 반환합니다.
 */
const hasGroupManagerPermission = () => {
    const permList = JSON.parse(sessionStorage.getItem("permissions"));
    if (!permList) return false;
    const permission = utility.GroupManagerPermission;
    const dict = permList.find(dict => utility.permissionGradeToNumber(dict.grade) === permission);
    return !!dict;
};

/**
 * 현재 사용자가 site 관리자 권한을 가지고 있는지 확인합니다.
 * @returns 현재 사용자가 site admin 권한을 가지고 있는지 여부를 반환합니다.
 */
 const hasSiteAdminPermission = () => {
    const permList = JSON.parse(sessionStorage.getItem("permissions"));
    if (!permList) return false;
    const permission = utility.SiteAdminPermission;
    const dict = permList.find(dict => utility.permissionGradeToNumber(dict.grade) === permission);
    return !!dict;
};

/**
 * 현재 사용자가 site 유저 권한을 가지고 있는지 확인합니다.
 * @returns 현재 사용자가 site member 권한을 가지고 있는지 여부를 반환합니다. (group member, group manager, site admin)
 */
 const hasSiteMemberPermission = () => {
    const permList = JSON.parse(sessionStorage.getItem("permissions"));
    if (!permList) return false;
    const permission = utility.MemberPermission;
    const dict = permList.find(dict => utility.permissionGradeToNumber(dict.grade) === permission);
    return !!dict;
};

/**
 * 현재 사용자가 group member 권한을 가지고 있는지 확인합니다.
 * @returns 현재 사용자가 group member 권한을 가지고 있는지 여부를 반환합니다.
 */
 const hasGroupMemberPermission = () => {
    const permList = JSON.parse(sessionStorage.getItem("permissions"));
    if (!permList) return false;
    const permission = utility.GroupMemberPermission;
    const dict = permList.find(dict => utility.permissionGradeToNumber(dict.grade) === permission);
    return !!dict;
};

/**
 * 사용자의 권한 정보를 session storage 에 저장합니다.
 * @see Api.getCurrentUserInfo
 * @param {*} optPermissions Api.getCurrentUserInfo({optPermission:true}) 의 결과값으로 받아온 .optPermissions field 값
 *                           falsy 라면, session storage 에서 permissions key 삭제
 */
const storePermissionsInfo = (optPermissions) => {
    if (optPermissions) {
        const groupPermissions = optPermissions.map(permission => ({
            _id: permission._id,
            grade: permission.grade,
        }));
        sessionStorage.setItem('permissions', JSON.stringify(groupPermissions));
    } else {
        sessionStorage.removeItem('permissions');
    }
};


/**
 * Map 표현에 필요한 설정의 기본 값을 가지고 오는 함수
 */
const getDefaultMapSetting = () => {
    return { lat: Config.lat, lng: Config.lng, zoom: Config.zoom };
};

/**
 * 도-분-초로 표현 된 GPS string을 decimal로 변경해주는 함수
 * 계산식 : 도 + 분/60 + 초/3600
 *
 * @param {*} DMS 도-분-초로 표현 된 GPS string
 * @returns decimal로 변경 된 GPS
 */
const gpsDMSToDecimal = (DMS) => {
    let degree, minute, second;

    [degree, DMS] = DMS.split(`°`);
    [minute, DMS] = DMS.split(`'`);
    [second, DMS] = DMS.split(`"`);

    return parseFloat(degree) + (parseFloat(minute) / 60) + (parseFloat(second) / 3600);
};

/**
 * 입력 란에 공백 입력 시 입력 되지 않도록 하는 함수
 *
 * @param {} e event
 */
const blankBlocking = (e) => {
    if (e.code === "Space") {
        e.preventDefault();
    }
};

const validateInputField = (errorCollection, errorKey, element, errorMessage=undefined) => {
    errorMessage || (errorMessage = i18n.t("15"));

    if (!element.value.trim()) {
        errorCollection[errorKey] = { state: false, message: errorMessage };
        element.classList.add("invalid");
        return false;
    }
    else {
        errorCollection[errorKey] = { state: false, message: "" };
        element.classList.remove("invalid");
        return true;
    }
};

const validateInputFieldEx = (errorCollection, errorKey, element, validate, errorMessage=undefined) => {
    errorMessage || (errorMessage = i18n.t("15"));

    if (!validate()) {
        errorCollection[errorKey] = { state: false, message: errorMessage };
        element.classList.add("invalid");
        return false;
    }
    else {
        errorCollection[errorKey] = { state: false, message: "" };
        element.classList.remove("invalid");
        return true;
    }
};

const validateSelectField = (errorCollection, errorKey, element, errorMessage=undefined) => {
    errorMessage || (errorMessage = i18n.t("15"));

    const value = element.options[element.selectedIndex]?.value;
    if (!value || !value.trim()) {
        errorCollection[errorKey] = { state: false, message: errorMessage };
        element.classList.add("invalid");
        return false;
    }
    else {
        errorCollection[errorKey] = { state: false, message: "" };
        element.classList.remove("invalid");
        return true;
    }
};

const validateFileField = (errorCollection, errorKey, element, storedFiles /*state 에 저장된 파일 목록*/, errorMessage=undefined) => {
    errorMessage || (errorMessage = i18n.t("15"));

    if (!storedFiles?.length) {
        errorCollection[errorKey] = { state: false, message: errorMessage };
        element.classList.add("invalid");
        return false;
    }
    else {
        errorCollection[errorKey] = { state: false, message: "" };
        element.classList.remove("invalid");
        return true;
    }
};

const validateEmailField = (errorCollection, errorKey, element, required = true, errorMessage=undefined) => {
    errorMessage || (errorMessage = i18n.t("15"));

    if (!element.value.trim() && required) {
        errorCollection[errorKey] = { state: false, message: errorMessage };
        element.classList.add("invalid");
        return false;
    }
    else if (element.value.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element.value.trim())) {
        errorCollection[errorKey] = { state: false, message: i18n.t("16") };
        element.classList.add("invalid");
        return false;
    }
    else {
        errorCollection[errorKey] = { state: false, message: "" };
        element.classList.remove("invalid");
        return true;
    }
};

const isValid = (elements, required) => {
    const errorCollection = {};
    let flag = true;
    elements.forEach((element) => {
        const error = isValidCheck(element, required);
        if (!error[0]) {
            element.classList.add("invalid");
            flag = false;
        }
        else element.classList.remove("invalid");
        errorCollection[element.name] = { state: error[0], message: error[1] };
    });

    return [flag, errorCollection];
};

const isValidCheck = (element, required = true) => {
    const name = element.name;
    const value = element.value.trim();

    switch (name) {
        case "email":
            return isValidEmail(value, required);
        case "realname":
            return isValidRealname(value, required);
        case "nickname":
            return isValidNickname(value, required);
        case "groupName":
            return isValidGroupName(value, required);
        case "password":
            return isValidPassword(value, required);
        case "passwordConfirm":
            return isValidPasswordConfirm(value, required);
        default:
            return isValidText(value, required);
        // case "name":
        //     return isValidName(value, required);
        // case "phone":
        //     return isValidPhone(value, required);
        // case "address1":
        //     return isValidAddress1(value, required);
        // case "text":
        //     return isValidText(value, required);
        // case "startDate":
        //     return isValidText(value, required);
        // case "endDate":
        //     return isValidText(value, required);
        // case "research":
        //     return isValidText(value, required);
    }
};

const isValidEmail = (value, required) => {
    if (required && !value) {
        return [false, i18n.t("15")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return [false, i18n.t("16")];
    }
    return [true, ""];
};

const isValidRealname = (value, required) => {
    if (required && value.length < 2) {
        return [false, i18n.t("912")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    else if (!/^[(가-힣a-zA-Z\s)]+$/.test(value)) {
        return [false, i18n.t("911")];
    }
    return [true, ""];
};

const isValidNickname = (value, required) => {
    if (required && value.length < 2) {
        return [false, i18n.t("325")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    else if (!/^[(가-힣a-zA-Z0-9\s)]+$/.test(value)) {
        return [false, i18n.t("326")];
    }
    return [true, ""];
};

const isValidGroupName = (value, required) => {
    if (required && value.length < 2) {
        return [false, i18n.t("394")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    else if (!/^[(가-힣a-zA-Z0-9\s)]+$/.test(value)) {
        return [false, i18n.t("395")];
    }
    return [true, ""];
};

const isValidPassword = (value, required) => {
    if (required && !value) {
        return [false, i18n.t("15")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    // 비밀번호 포멧을 정규식으로 체크 : 소문자로 시작 | 대문자로 시작 | 숫자로 시작하는 정규식 체크
    else if (!/^((?=.*[a-z])((?=.*[A-Z])|(?=.*\d)|(?=.*[^\da-zA-Z\s]))|(?=.*[A-Z])((?=.*[a-z])|(?=.*\d)|(?=.*[^\da-zA-Z\s]))|(?=.*\d)((?=.*[a-z])|(?=.*[A-Z])|(?=.*[^\da-zA-Z\s]))).{8,255}$/.test(value)) {
        return [false, i18n.t("286")];
    }
    return [true, ""];
};

const isValidPasswordConfirm = (value, required) => {
    if (required && !value) {
        return [false, i18n.t("15")];
    }
    else if (!required && !value) {
        return [true, ""];
    }
    else if (document.getElementsByName("password")[0].value !== value) {
        return [false, i18n.t("309")];
    }
    return [true, ""];
};

const isValidText = (value, required) => {
    if (required && !value) {
        return [false, i18n.t("15")];
    }
    return [true, ""];
};

const convertFileName = (fileName, ellipsisLen = 20) => {
    const [name, extension] = fileName.split(".");
    if (name.length > ellipsisLen) {
        const ellipsisAt = ellipsisLen === 20 ? 8 : ellipsisLen / 2;
        return name.slice(0, ellipsisAt) + "..." + name.slice(-ellipsisAt) + "." + extension;
    }
    return fileName;
};

const setDateRange = (e, startId, endId) => {
    const startDate = document.getElementById(startId);
    const endDate = document.getElementById(endId);

    // max date인 9999-12-31을 초과한 경우 max date 값으로 세팅
    if (e.target.validity.rangeOverflow) {
        e.target.value = e.target.max;
    }

    if ( e.target.id === startId ) {
        endDate.min = startDate.value;
    }
    else {
        startDate.max = endDate.value;
    }
};

const dateVerify = (e, startId, endId) => {
    const startDate = document.getElementById(startId);
    const endDate = document.getElementById(endId);

    // max date인 9999-12-31을 초과한 경우 max date 값으로 세팅
    if (e.target.validity.rangeOverflow) {
        e.target.value = e.target.max;
    }

    if (e.target.id === startId && startDate.max < startDate.value) {
        startDate.value = startDate.max;
        endDate.min = startDate.max;
    }
    if (e.target.id === endId && endDate.min > endDate.value) {
        endDate.value = endDate.min;
        startDate.max = endDate.min;
    }
};

// convert 'YYYY-MM-DD' locale date to iso date
const convertToIsoDate = (date, add_day = undefined) => {
    if (!date) return '';

    if (date.split('T').length > 1) {
        // already iso string conversion-ed
        return date;
    }

    // parse date string
    let date_param = date.split('-');
    if (date_param.length !== 3) {
        dgLogger.error('error: invalid date string, date: ' + date)();
        return null;
    }

    // create date with 'year', 'month', 'day'
    // notes, redkur; month param is zero-based index
    let _date = new Date(date_param[0], date_param[1] - 1, date_param[2]);

    // select next day to include current date
    if (add_day) {
        _date.setDate(_date.getDate() + add_day);
    }

    return _date.toISOString();
};

// convert locale date to 'YYYY-MM-DD'
function convertToDate(date, delimiter = '-') {
    if (typeof date === 'string') {
        const parts = date.match(/\d+/g); // 숫자 부분 추출
        if (!parts) return "";

        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1;
        const day = parseInt(parts[2]);
        date = new Date(year, month, day);
    }

    if (!date || Number.isNaN(date.getTime())) return "";

    const leftPad = ((value) => {
        if (value >= 10) {
            return value;
        }

        return `0${value}`;
    });
    const year = date.getFullYear();
    const month = leftPad(date.getMonth() + 1);
    const day = leftPad(date.getDate());

    return [year, month, day].join(delimiter);
};

const isEqualFile = (src, tgt) => src.name === tgt.name && src.size === tgt.size;

/**
 * react-dropzone 으로 업로드한 파일드이 최대 파일 갯수, 전체 파일 용량을 넘는지 (유효성을) 체크합니다.
 * 새로 추가된 파일 및 전체 파일 목록과 유효성 체크한 결과를 반환합니다.
 *
 * @param {*} acceptedFiles (acceptable 한) 업로드할 파일
 * @param {*} fileRejections (reject 될 수 있는) 업로드할 파일
 * @param {*} containFiles 기존에 이미 가지고 있는 업로드할 파일
 * @param {*} MAX_FILES 최대 업로드 가능한 파일 갯수 (미 지정(null) 일 경우, check 하지 않음)
 * @param {*} MAX_SIZE 최대 업로드 가능한 파일 용량 (미 지정(null) 일 경우, check 하지 않음)
 * @param {*} filter 업로드할 파일의 filterling list (필터링 리스트에 맞는 항목만 업로드 가능함. 빈 리스트[] 일 경우, check 하지 않음)
 * @returns [new added files, all files, validationResult]
 */
const validateOnDrop = (acceptedFiles, fileRejections, containFiles, MAX_FILES, MAX_SIZE, filters = []) => {
    const _files = fileRejections
        // .filter(item => !item.errors.find(error => error.code === DropZoneErrorCode.FileInvalidType))
        .map(item => item.file)
        .concat(acceptedFiles)
        .filter(newFile => !containFiles.find(file => isEqualFile(file, newFile)));

        const files =  [...containFiles, ..._files];
        const sizeOfFiles = files.reduce((acc, cur) => acc + (cur.size || 0), 0);
        const result = {
            maxFiles: MAX_FILES ? files.length <= MAX_FILES : true,
            maxSize: MAX_SIZE ? sizeOfFiles <= MAX_SIZE: true,
            mimeType: filters.length > 0 ? _files.every(item => filters.some(filter => item.type.startsWith(filter))) : true
        };
        result.valid = (result.maxFiles && result.maxSize && result.mimeType);

    return [_files, files, result]; // new added files, all files, valid
};

const scrollToInvalidElement = (className = "invalid", option = { block: "center", behavior: "smooth" }) => {
    document.getElementsByClassName(className)[0]?.scrollIntoView(option);
};

const getStorageUrl = (key, bucketName, Expires = 600) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REDGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    const url = S3.getSignedUrl('getObject', {
        Bucket: bucketName,
        Key: key,
        Expires: Expires
    });

    return url;
};

const copyUrlToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            dgLogger.error(`Copy to clipboard failed. ${ex}`)();
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
};

const getDayDiff = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffDate = date1.getTime() - date2.getTime();

    return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
};

const scrollToElement = (element, {block, behavior} = { block: "center", behavior: "smooth" }) => {
    if (!element) return;
    element.scrollIntoView({ block: block, behavior: behavior });
};

/**
 * 전달한 문자열 리스트를 단일 문자열로 만들어 반환하는 함수
 * 
 * @param {*} list [{value,},...] 포멧의 리스트
 * @returns 문자열 리스트
 */
const converSelectedListToString = (list) => {
    return list.reduce((acc, item) => {
        acc += item.value + "|";
        return acc;
    }, "").slice(0, -1);
};

const getReportFilterUrl = ({groups, selectProjects, locations, startDate, endDate, date, name, myReport, keyword}) => {
    const groupsString = converSelectedListToString(groups||[]);
    const projectsString = converSelectedListToString(selectProjects||[]);
    const locationsString = converSelectedListToString(locations||[]);
    return `groups=${groupsString}&projects=${projectsString}&locations=${locationsString}&startDate=${startDate||''}&endDate=${endDate||''}&date=${date||''}&name=${name}&myReport=${myReport||false}&keyword=${keyword||''}`;
};

const getReportListBasedUrl = ({groups, selectProjects, locations, startDate, endDate, date, name, myReport, keyword}) => {
    const filter = getReportFilterUrl({groups, selectProjects, locations, startDate, endDate, date, name, myReport, keyword});
    const url = `/Report/${VIEW.ListBased}/` + filter + `/page=1`;
    return url;
};

const navigateBack = (navigate) => {
    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
    const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
    sessionStorage.setItem("prevPathname", prevPathname);
    navigate(navigatePathname || "/", { replace: true });
};

const i18nGroupName = (group_name) => {
    return strings.helper.i18nGroupName(group_name, getLang());
};

const getLang = () => {
    return (i18n.languages[0] == "ko" || i18n.languages[0].startsWith("ko-")) ? "ko" : "en";
};

/**
 * form item field 에서 빈 값인 key 를 제외한 객체를 반환
 *
 * @param {object} fields form item field 정보 (제목, 설명, 기타의 속성) 를 갖고 있는 객체
 * @param {list} filters fields 에서 filter 를 통해 남길 item 의 key 리스트. (만약 falsy 라면, 모든 item 을 남김)
 * @returns 파라메터로 전달된 원본 객체에서, 빈 값인 key 를 제외한 객체
 */
const fieldsWithoutEmptyValue = (fields, filters = ["title", "description", "description2", "drag_and_drop_description", "button_text", "unit"]) => {
    return Object.keys(fields)
    .filter((k) => filters ? filters.includes(k) : true)
    .filter((k) => !([null, undefined, ""].includes(fields[k]))) // if null/undefined/"" then filter out
    .reduce((acc, key) => {
        acc[key] = fields[key];
        return acc;
    }, {});
};

/**
 * form item 의 sub_items 에서 빈 값인 key 를 제외한 객체 목록을 반환
 *
 * @param {object[]} sub_items form item 의 sub_items 정보를 갖고 있는 객체 목록
 * @param {list} sub_item sub_item 에서 filter 를 통해 남길 item 의 key 리스트. (만약 falsy 라면, 모든 item 을 남김)
 * @returns 파라메터로 전달된 원본 객체 목록에서, 빈 값인 key 를 제외한 객체 목록
 */
const subItemsWithoutEmptyValue = (sub_items, filters = ["name", "question", "index"]) => {
    // sub_items 가 있다면, 번역 가능한 문자열이 있는 key 만 복사
    // from --
    // "sub_items": [
    //   {
    //       "sub_item_type": "textbox+file",
    //       "name": "구간1 : ",
    //       "index": 0,
    //       "_id": "663b387b6cc38aa7e1f5b45c",
    //       "items": [
    //           { "_id": "663b387b6cc38aa7e1f5b45d", "index": 0, "sub_item_type": "textbox", "name": "", "unit": "L" },
    //           { "_id": "663b387b6cc38aa7e1f5b45e", "index": 1, "sub_item_type": "file", "name": "", "max_item_count": 1 }
    //       ]
    //   }
    // },
    // to --
    // "sub_items": [
    //   {
    //       "name": "구간1 : ",
    //       "index": 0,
    //   },
    // ]
    return sub_items?.map((sub_item) => ({
        ...Object.keys(sub_item)
            // Note. sub_item 에 포함된 항목중 items 는 backend 서버에서 임의로 생성하는 field 이므로,
            // 이 field 는 번역을 수행하지 않습니다. (또한, 실제 번역이 필요한 field 로 보이지 않습니다.)
            .filter((k) => filters ? filters.includes(k) : true)
            .filter((k) => !([null, undefined, ""].includes(sub_item[k]))) // if null/undefined/"" then filter out
            .reduce((acc, key) => {
                acc[key] = sub_item[key];
                return acc;
            }, {}),
    })) || [];
};

const BOUNDINGBOX_COLOR = [
    "#33FF04",
    "#8B03FF",
    "#FF0081",
    "#FF9B00",
    "#008FFF",
    "#FAEB2C",
    "#FF0000",
    "#B7FF00",
    "#90FFCA",
    "#B2ADCF",
    "#EF8CAC",
    "#FF7878",
    "#5B5EFD",
    "#04CAF4",
    //--generated by gpt-------------------
    "#FFC0CB",
    "#ADFF2F",
    "#8A2BE2",
    "#00FFFF",
    "#FFA500",
    "#7CFC00",
    "#DC143C",
    "#FF8C00",
    "#00FF7F",
    "#FF00FF",
    "#7B68EE",
    "#00FA9A",
    "#1E90FF",
    "#FF1493",
    "#006400",
    "#40E0D0",
    "#9ACD32",
    "#FF69B4",
    "#008080",
    "#FF4500",
    "#32CD32",
    "#8B008B",
    "#ADD8E6",
    "#FFA07A",
    "#7FFFD4",
    "#483D8B",
    "#FF6347",
    "#FFB6C1",
    "#20B2AA",
    "#DA70D6",
    "#98FB98",
    "#DB7093",
    "#AFEEEE",
    "#FFDEAD",
    "#0080FF",
    "#C71585",
    "#FF44CC",
    "#FFE4B5",
    "#9B30FF",
    "#FFD700",
    "#CD5C5C",
    "#FFE4C4",
    "#008B8B",
    "#FF7F50",
    "#DAA520",
    "#FF6347"
];


const useGetNthBoundingBoxColor = () => {

    const getNthBoundingBoxColor = useCallback((index) => {
        function hexToRgba(hex) {
            // Hex 코드를 정규화 (e.g., #FFF 를 #FFFFFF로 확장)
            if (hex.length === 4) {
                hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
            }

            // Hex 값을 파싱
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);

            // RGB 반환
            return [r, g, b];
        }

        if (index < BOUNDINGBOX_COLOR.length) {
            return hexToRgba(BOUNDINGBOX_COLOR[index]);
        } else {
            const n = index % BOUNDINGBOX_COLOR.length;
            return hexToRgba(BOUNDINGBOX_COLOR[n]);
        }
    }, []);

    return getNthBoundingBoxColor;
};

const capitalizeFirstLetter = (text) => {
    if (!text) return text; // 빈 문자열 예외 처리
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

/**
 * 현재 시간(Date.now) 이 파라메터로 전달된 시간 range 사이에 있는지 확인
 *
 * @param {str} startAt 시작 시간
 * @param {str} endAt 종료 시간
 * @returns 현재 시간이 startAt - endAt 사이의 시간이라면 true 반환
 */
const isCurrentTimeBetween = (startAt, endAt) => {
    if (!startAt || !endAt) return false;

    const now = new Date();
    const startAtDate = new Date(startAt);
    const endAtDate = new Date(endAt);
    return startAtDate <= now && now <= endAtDate;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    hasGroupManagerPermission,
    hasGroupMemberPermission,
    hasSiteAdminPermission,
    hasSiteMemberPermission,
    storePermissionsInfo,
    getDefaultMapSetting,
    gpsDMSToDecimal,
    blankBlocking,
    isValid,
    convertFileName,
    setDateRange,
    dateVerify,
    validateInputField,
    validateInputFieldEx,
    validateSelectField,
    validateFileField,
    validateEmailField,
    convertToIsoDate,
    convertToDate,
    validateOnDrop,
    isEqualFile,
    scrollToInvalidElement,
    getStorageUrl,
    copyUrlToClipboard,
    getDayDiff,
    scrollToElement,
    converSelectedListToString,
    getReportFilterUrl,
    getReportListBasedUrl,
    navigateBack,
    i18nGroupName,
    getLang,
    fieldsWithoutEmptyValue,
    subItemsWithoutEmptyValue,
    useGetNthBoundingBoxColor,
    capitalizeFirstLetter,
    isCurrentTimeBetween
};
