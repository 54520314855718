import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import { useUserinfo } from "../../common/customHook";
import "./Maintenance.css";
import { useIsMount } from "../../common/customHook";
import dgLogger from "../../common/dgLogger";
import { PopupJoinGroupApproved, PopupJoinGroupRejectReason, PopupRequestJoinGroup, PopupRequestedJoinGroup } from "../common/RequestGroupMemberWindow";
import { LinkCopyWindow } from "../common/LinkCopyWindow";
import AskQuestion from "../More/AskQuestion";
import LoginRecommendWindow from "../common/LoginRecommendWindow";
import GroupParticipationWindow from "../common/GroupParticipationWindow";
import CheckForTempReportWindow from "../common/CheckForTempReportWindow";
import { DynamicFormNoModification, DynamicFormRequest, DynamicFormRequested } from "../common/DynamicFormWindow";
import ImageUploadPendingWindow from "../common/ImageUploadPendingWindow";
import { PopupValidateFile, PopupValidateImage } from "../common/ValidateModal";
import { RequestMailCodeWindow } from "../common/MailAuthenticationWindow";
import PostcodeWindow from "../PostcodeWindow";
import SiteMemberInfoWindow from "./ManageMember/SiteMemberInfoWindow";
import { PopupRequestReportLicense, PopupRequestedReportLicense } from "../common/RequestDataUseWindow";
import GroupInfoApplicationWindow from "../common/GroupInfoApplicationWindow";
import { UpdateMemberGrade } from "./ManageMember/UpdateMemberGradeWindow";
import { useTranslation } from "react-i18next";
import { PopupRemoveReport } from "../common/RemoveWindow";
import { PopupRequestedCreateGroup } from "./RequestGroupCreation";
import { PopupTermWarning } from "../Login/Signup";
import { PopupWithdrawSiteMemberRequest, PopupWithdrawSiteMemberRequested } from "./MyInfo";
import { PopupGroupJoinRejectReason, PopupGroupJoinReject } from "./ApproveGroupMember";
import { PopupDataUseReject, PopupDataUseRejectReason } from "./ApproveDataUseRequest";
import { PopupGroupCreationReject, PopupGroupCreationRejectReason } from "./ApproveGroupCreation";
import { InputText } from "../Input";
import SelectWrapper from "../CreateReport/common/SelectWrapper";

const POPUP = {
    ...GeneralPopup,
    UpdateReportLicenseStateFailure: 1004,
    RemoveGroupFailure: 1005,
    PopupRequestJoinGroup: 1006,
    PopupRequestedJoinGroup: 1007,
    PopupJoinGroupRejectReason: 1008,
    LinkCopyWindow: 1009,
    GeneralErrorWindow: 1010,
    AskQuestion: 1011,
    LoginRecommendWindow: 1012,
    GroupParticipationWindow: 1013,
    CheckForTempReportWindow: 1014,
    DynamicFormRequest: 1015,
    DynamicFormRequested: 1016,
    DynamicFormNoModification: 1017,
    ImageUploadPendingWindow: 1018,
    PopupValidateImage: 1019,
    PopupValidateFile: 1020,
    RequestMailCodeWindow: 1021,
    PostcodeWindow: 1022,
    SiteMemberInfoWindow: 1023,
    PopupRequestReportLicense: 1024,
    GroupInfoApplicationWindow: 1025,
    PopupJoinGroupApproved: 1026,
    UpdateMemberGrade: 1027,
    PopupRequestedReportLicense: 1028,
    PopupRemoveReport: 1029,
    PopupRequestedCreateGroup: 1030,
    PopupTermWarning: 1031,
    PopupWithdrawSiteMemberRequest: 1032,
    PopupWithdrawSiteMemberRequested: 1033,
    PopupGroupJoinReject: 1034,
    PopupGroupJoinRejectReason: 1035,
    PopupDataUseRejectReason: 1036,
    PopupDataUseReject: 1037,
    PopupGroupCreationReject: 1038,
    PopupGroupCreationRejectReason: 1039,
};

const TAB_ID = {
    modals: "maint-modals",
    events: "maint-events",
    notifications: "maint-notifications"
};

export default function Maintenance() {
    const { t } = useTranslation();
    const { userInfo } = useUserinfo();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        activeTab: TAB_ID.modals, // 현재 tab
        models: [],
        groupID: null,
    });
    const [notMemberedGroups, setNotMemberedGroups] = useState([]);

    const navigate = useNavigate();
    const isMount = useIsMount();

    useEffect(() => {
        const hasPermission = common.hasSiteAdminPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            if (!isMount.current) return;
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: `현재 권한으로는 접근할 수 없습니다.`,
            }));
            return;
        }

        Api.getNotMemberedGroupList().then(payload => {
            // console.log(payload);
            const groups = payload.groups.filter(v => v.require_join_confirm);
            setNotMemberedGroups(groups);
            if (groups.length > 0) {
                setState(prev => ({...prev, groupID: groups[0]._id}));
            }
        });
    }, [isMount]);

    const popups = () => {
        switch (state.popup) {
            case POPUP.PopupGroupCreationReject:
                return <PopupGroupCreationReject
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => {
                    console.log("onCancel called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                />;
            case POPUP.PopupGroupCreationRejectReason:
                return <PopupGroupCreationRejectReason
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                reason="test reason"
                />;
            case POPUP.PopupDataUseReject:
                return <PopupDataUseReject
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => {
                    console.log("onCancel called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                />;
            case POPUP.PopupDataUseRejectReason:
                return <PopupDataUseRejectReason
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                reason="test reason"
                />;
            case POPUP.PopupGroupJoinRejectReason:
                return <PopupGroupJoinRejectReason
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                reason="test reason"
                />;

            case POPUP.PopupGroupJoinReject:
                return <PopupGroupJoinReject
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => {
                    console.log("onCancel called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                />;

            case POPUP.PopupRequestedCreateGroup:
                return <PopupRequestedCreateGroup
                    onClose={() => {
                        console.log("onClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}/>;
            case POPUP.PopupWithdrawSiteMemberRequest:
                return <PopupWithdrawSiteMemberRequest
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => {
                    console.log("onCancel called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                />;
            case POPUP.PopupWithdrawSiteMemberRequested:
                return <PopupWithdrawSiteMemberRequested
                onRequestClose={() => {
                    console.log("onRequestClose called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    console.log("onConfirm called");
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                />;
            case POPUP.PopupTermWarning:
                return (
                    <PopupTermWarning
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}/>
                );
            case POPUP.PopupRemoveReport:
                return (
                    <PopupRemoveReport
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestedReportLicense:
                return (
                    <PopupRequestedReportLicense
                        onRequestClose={() => {
                            console.log("onRequestClose called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.UpdateMemberGrade:
                const users = state.popupTarget.users;
                const grades = state.popupTarget.grades;
                return (
                    <UpdateMemberGrade
                        users={users}
                        grades={grades}
                        title={t("434")}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        className={""}
                        onConfirmed={(_, __) => {
                            console.log("onConfirmed called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupJoinGroupApproved:
                return (
                    <PopupJoinGroupApproved
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GroupInfoApplicationWindow:
                return (
                    <GroupInfoApplicationWindow
                        group={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestReportLicense:
                return (
                    <PopupRequestReportLicense
                        onSubmit={() => {
                            console.log("onSubmit called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.SiteMemberInfoWindow:
                return (
                    <SiteMemberInfoWindow
                        user={state.popupTarget}
                        groups={[]}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PostcodeWindow:
                return (
                    <PostcodeWindow
                        onComplete={(address1, address2) => {
                            console.log("onComplete called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.RequestMailCodeWindow:
                return (
                    <RequestMailCodeWindow
                        onRequestClose={() => {
                            console.log("onRequestClose called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        mail={"testuser@oceancloud.co.kr"}
                    />
                );
            case POPUP.PopupValidateFile:
                return (
                    <PopupValidateFile
                        maxFiles={20}
                        maxSizeInMB={2000}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupValidateImage:
                return (
                    <PopupValidateImage
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.ImageUploadPendingWindow:
                return (
                    <ImageUploadPendingWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormNoModification:
                return (
                    <DynamicFormNoModification
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormRequested:
                return (
                    <DynamicFormRequested
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormRequest:
                return (
                    <DynamicFormRequest
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.CheckForTempReportWindow:
                return (
                    <CheckForTempReportWindow
                        overrideOnClick={() => {
                            console.log("overrideOnClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GroupParticipationWindow:
                return (
                    <GroupParticipationWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.LoginRecommendWindow:
                return (
                    <LoginRecommendWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.AskQuestion:
                return (
                    <AskQuestion
                        user={userInfo}
                        onSend={() => {
                            console.log("onSend called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.LinkCopyWindow:
                return (
                    <LinkCopyWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupJoinGroupRejectReason:
                return (
                    <PopupJoinGroupRejectReason
                        state_reason={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestJoinGroup:
                return (
                    <PopupRequestJoinGroup
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        groupName={"Example Group"}
                    />
                );
            case POPUP.PopupRequestedJoinGroup:
                return (
                    <PopupRequestedJoinGroup
                        require_join_confirm={state.popupTarget.require_join_confirm}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GeneralErrorWindow:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GeneralError:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            case POPUP.UpdateReportLicenseStateFailure:
            case POPUP.RemoveGroupFailure:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            setState({ ...state, popup: POPUP.None, popupTarget: undefined });
                        }}
                    />
                );
            case POPUP.None:
                break;
            default:
                console.log(`un-handled popup (${state.popup})`);
                break;
        }
    };

    const ModalTableRow = ({desc, onClick}) => {
        return (<Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
        <Col className="item col-10">{desc}</Col>
        <Col className="item col-2 text-center">
        <button
                className="execute notosanskr-400 font-size-16"
                type="button"
                onClick={onClick}
            >
                열기
            </button>
        </Col>
    </Row>);
    };

    const generateRandomString = useCallback((length = 16) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            result += characters.charAt(randomIndex);
        }

        return result;
    }, []);

    return (
        <div id="maintenance">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">Development</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">동작 확인</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(k) => {
                    setState({ ...state, activeTab: k });
                }}
            >
                <Tab className={TAB_ID.modals} eventKey={TAB_ID.modals} title="팝업">
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item col-10">설명 (기능 동작 X)</Col>
                        <Col className="item col-2 text-center">실행</Col>
                    </Row>

{/*
                    <ModalTableRow desc={"WithdrawGroupMember"} onClick={(_) => setState({...state, popup: POPUP.WithdrawGroupMember, popupTarget: undefined})} />
                    <ModalTableRow desc={"WithdrawGroupMemberRequest"} onClick={(_) => setState({...state, popup: POPUP.WithdrawGroupMemberRequest, popupTarget: undefined})} />
                    <ModalTableRow desc={"WithdrawGroupMemberRequested"} onClick={(_) => setState({...state, popup: POPUP.WithdrawGroupMemberRequested, popupTarget: undefined})} />
                    <ModalTableRow desc={"MailTransferWindow"} onClick={(_) => setState({...state, popup: POPUP.MailTransferWindow, popupTarget: undefined})} />
                    <ModalTableRow desc={"MailTransferCompletedWindow"} onClick={(_) => setState({...state, popup: POPUP.MailTransferCompletedWindow, popupTarget: undefined})} />
                    <ModalTableRow desc={"MailTransferFailureWindow"} onClick={(_) => setState({...state, popup: POPUP.MailTransferFailureWindow, popupTarget: undefined})} />
                    <ModalTableRow desc={"UserInfoUpdateWindow"} onClick={(_) => setState({...state, popup: POPUP.UserInfoUpdateWindow, popupTarget: undefined})} />
                    <ModalTableRow desc={"WithdrawSiteMember"} onClick={(_) => setState({...state, popup: POPUP.WithdrawSiteMember, popupTarget: undefined})} />
                    <ModalTableRow desc={"WithdrawSiteMemberRequest"} onClick={(_) => setState({...state, popup: POPUP.WithdrawSiteMemberRequest, popupTarget: undefined})} />
                    <ModalTableRow desc={"WithdrawSiteMemberRequested"} onClick={(_) => setState({...state, popup: POPUP.WithdrawSiteMemberRequested, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupRemoveProject"} onClick={(_) => setState({...state, popup: POPUP.PopupRemoveProject, popupTarget: undefined})} /> 
                    <ModalTableRow desc={"ImagePreviewWindow"} onClick={(_) => setState({...state, popup: POPUP.ImagePreviewWindow, popupTarget: undefined})} />
                    <ModalTableRow desc={"CheckForTempReportWindow"} onClick={(_) => setState({...state, popup: POPUP.CheckForTempReportWindow, popupTarget: undefined})} /> 
*/}
                    <ModalTableRow desc={"PopupGroupCreationReject"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupGroupCreationReject, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupGroupCreationRejectReason"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupGroupCreationRejectReason, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupDataUseReject"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupDataUseReject, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupDataUseRejectReason"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupDataUseRejectReason, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupGroupJoinReject"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupGroupJoinReject, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupGroupJoinRejectReason"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupGroupJoinRejectReason, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupRequestedCreateGroup"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupRequestedCreateGroup, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupWithdrawSiteMemberRequest"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupWithdrawSiteMemberRequest, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupWithdrawSiteMemberRequested"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupWithdrawSiteMemberRequested, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupTermWarning"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupTermWarning, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupRemoveReport"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupRemoveReport, popupTarget: undefined})} />
                    <ModalTableRow desc={"PopupRequestedReportLicense (자료 사용권 신청 완료)"}
                        onClick={(_) => setState({...state, popup: POPUP.PopupRequestedReportLicense, popupTarget: undefined})} />
                    <ModalTableRow desc={"UpdateMemberGrade (그룹원 등급 변경)"}
                        onClick={async (_) => {
                            const groupListPayload = await Api.getFilterGroupList();
                            console.log(groupListPayload[0]._id);
                            const groupMemberPayload = await Api.getFilteredGroupMember({groups: [groupListPayload[0]._id]});
                            console.log(groupListPayload[0].permissions);
                            const popupTarget = { users: groupMemberPayload.users, grades: groupListPayload[0].permissions };
                            setState({ ...state, popup: POPUP.UpdateMemberGrade, popupTarget: popupTarget});
                        }}/>
                    <ModalTableRow desc={"GroupInfoApplicationWindow (그룹 생성 신청서)"}
                        onClick={async (_) => {
                            const payload = await Api.getGroupHistoryAll({
                                states: [utility.STATE.ACQUIRED],
                                currentPage: 0,
                                itemsCountPerPage: 20,
                                sort: { register_date: -1 },
                            });
                            const reviews = payload.histories;
                            setState({ ...state, popup: POPUP.GroupInfoApplicationWindow, popupTarget: await Api.getGroupHistoryItem(reviews[0]._id)});
                        }}/>
                    <ModalTableRow desc={"PopupRequestReportLicense (자료 사용권 신청서)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestReportLicense, popupTarget: undefined})}/>
                    <ModalTableRow desc={"SiteMemberInfoWindow (회원 정보)"}
                        onClick={async (_) => {
                            const userInfo = await Api.getUserInfo({ _id: sessionStorage.getItem("_id") });
                            setState({ ...state, popup: POPUP.SiteMemberInfoWindow, popupTarget: userInfo });
                        }}/>
                    <ModalTableRow desc={"PostcodeWindow (주소 검색)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PostcodeWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"RequestMailCodeWindow (메일 인증)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.RequestMailCodeWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"PopupValidateFile"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupValidateFile, popupTarget: undefined})}/>
                    <ModalTableRow desc={"PopupValidateImage (파일 첨부 실패)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupValidateImage, popupTarget: undefined})}/>
                    <ModalTableRow desc={"ImageUploadPendingWindow"}
                        onClick={(_) => setState({ ...state, popup: POPUP.ImageUploadPendingWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"DynamicFormNoModification (입력폼 변경)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormNoModification, popupTarget: undefined})}/>
                    <ModalTableRow desc={"DynamicFormRequested (입력폼 변경)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormRequested, popupTarget: undefined})}/>
                    <ModalTableRow desc={"DynamicFormRequest (입력폼 변경)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormRequest, popupTarget: undefined})}/>
                    <ModalTableRow desc={"CheckForTempReportWindow (임시 저장된 자료가 있습니다)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.CheckForTempReportWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"GroupParticipationWindow (그룹 참여)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.GroupParticipationWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"LoginRecommendWindow"}
                        onClick={(_) => setState({ ...state, popup: POPUP.LoginRecommendWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"AskQuestion"}
                        onClick={(_) => setState({ ...state, popup: POPUP.AskQuestion, popupTarget: undefined})}/>
                    <ModalTableRow desc={"GeneralErrorWindow"}
                        onClick={(_) => setState({ ...state, popup: POPUP.GeneralErrorWindow, popupTarget: "테스트 에러 메세지 입니다."})}/>
                    <ModalTableRow desc={"LinkCopyWindow"}
                        onClick={(_) => setState({ ...state, popup: POPUP.LinkCopyWindow, popupTarget: undefined})}/>
                    <ModalTableRow desc={"PopupJoinGroupApproved (그룹 참여 승인 완료)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupJoinGroupApproved, popupTarget: undefined})}/>
                    <ModalTableRow desc={"PopupJoinGroupRejectReason (그룹 참여 거절)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupJoinGroupRejectReason, popupTarget: "테스트니까. 거부합니다."})}/>
                    <ModalTableRow desc={"PopupRequestedJoinGroup (그룹 참여 승인 완료)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedJoinGroup, popupTarget: {require_join_confirm: false}})}/>
                    <ModalTableRow desc={"PopupRequestedJoinGroup (그룹 참여 신청 완료)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedJoinGroup, popupTarget: {require_join_confirm: true}})}/>
                    <ModalTableRow desc={"PopupRequestJoinGroup (그룹 참여 신청)"}
                        onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestJoinGroup, popupTarget: undefined})}/>
                </Tab>
                <Tab className={TAB_ID.events} eventKey={TAB_ID.events} title="이벤트">
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item col-10">설명</Col>
                        <Col className="item col-2 text-center">실행</Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">자료 사용권 신청 거절 메일 전송 (브라우저 언어에 따른 처리)</Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    try {
                                        await Api.updateReportLicenseState({
                                            histories: [],
                                            state: utility.STATE.REJECTED,
                                            reason: "test message",
                                            lang: common.getLang(),
                                        });
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        setState({ ...state, popup: POPUP.UpdateReportLicenseStateFailure, popupTarget: e.message });
                                        return;
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">그룹생성 알림전송 (<span style={{color: "red"}}>주의!!</span> 실제 관리자에게 그룹 생성 요청이 발생됨)</Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    try {
                                        await Api.requestGroupCreation({
                                            name: `fake-group-${generateRandomString(16)}`,
                                            userName: `fake-name-${generateRandomString(16)}`,
                                            contact: `fake-contact-${generateRandomString(16)}`,
                                            research: `fake-research-${generateRandomString(32)}`,
                                            rel_link: `http://fake-link-${generateRandomString(16)}`,
                                            require_join_confirm: false,
                                            about: `fake-about-${generateRandomString(128)}`,
                                            lang: common.getLang()
                                        });
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                                        return;
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">
                            <Row>
                                <Col>
                                    자료 사용권 요청 알림전송 (<span style={{color: "red"}}>주의!!</span> 실제 자료 소유자에게 사용권 요청이 발생됨)
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">자료 id 들 (',' 로 구분)</Col>
                                <Col>
                                    <InputText
                                        id="report-ids"
                                        defaultValue={""}
                                        className="w-100"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    const value = document.getElementById("report-ids").value;
                                    const reportIDs = value.split(',').map(v => v.trim()).filter(v => v != "");
                                    if (reportIDs.length == 0) return console.warn("no report ids. ignore it");

                                    try {
                                        await Api.requestReportLicense({
                                            reportIds: reportIDs,
                                            name: `fake-group-${generateRandomString(16)}`,
                                            email: `fake-email-${generateRandomString(16)}`,
                                            contact: `fake-contact-${generateRandomString(16)}`,
                                            cause: `fake-cause-${generateRandomString(32)}`,
                                            lang: common.getLang()
                                        });
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                                        return;
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">
                            <Row>
                                <Col>
                                    그룹 가입 요청 알림전송 (<span style={{color: "red"}}>주의!!</span> 실제 그룹 매니저에게 가입 요청이 발생됨)
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">승인 필요한 미 가입 그룹</Col>
                                <Col>
                                    <SelectWrapper
                                        className="w-100"
                                        onChange={(e) => {
                                            console.log(e);
                                            setState({...state, groupID: e.target.value});
                                        }}
                                        list={notMemberedGroups.map((v) => ({...v, name: common.i18nGroupName(v.name)}))}
                                        placeholder={""}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    if (state.groupID == null) return console.warn("no selected group. ignore it");

                                    try {
                                        await Api.requestJoinGroupMember({
                                            _id: state.groupID,
                                            lang: common.getLang()
                                        });
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                                        return;
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">
                            <Row className="">
                                <Col className="item col-12">지정된 사용자에게 메일 (500개) 전달. (https://temp-mail.org/ 임시 메일 주소 생성해서 테스트할것)</Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">메일 받을 사용자(email 주소)</Col>
                                <Col>
                                    <InputText
                                        id="email-to"
                                        defaultValue={""}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">메일 제목</Col>
                                <Col>
                                    <InputText
                                        className="w-100"
                                        id="email-title"
                                        defaultValue={""}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">메일 본문</Col>
                                <Col>
                                    <textarea
                                        style={{
                                            width: "100%",
                                            height: "100px"}}
                                        id="email-body"
                                        defaultValue={""}
                                        placeholder={"이것은 메일 본문입니다.\n여러줄로 출력될 수 있을까요?\n확인해 볼 필요가 있네요."}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    try {
                                        const to = document.getElementById("email-to").value;
                                        const title = document.getElementById("email-title").value;
                                        const body = document.getElementById("email-body").value;
                                        console.log(`to : ${to}`);
                                        console.log(`title : ${title}`);
                                        console.log(`body : ${body}`);
                                        const users = new Array(500).fill({to, title, body});
                                        await Api.sendBulkMail({users});
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        // setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                                        alert(e.message);
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                        <Col className="item col-10">
                            <Row className="">
                                <Col className="item col-12">지정된 사용자(on Mobile App) 에게 알림 전달</Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">알림 받을 사용자(email)</Col>
                                <Col>
                                    <InputText
                                        id="notification-to"
                                        defaultValue={""}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">알림 제목</Col>
                                <Col>
                                    <InputText
                                        className="w-100"
                                        id="notification-title"
                                        defaultValue={""}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">알림 본문</Col>
                                <Col>
                                    <textarea
                                        style={{
                                            width: "100%",
                                            height: "100px"}}
                                        id="notification-desc"
                                        defaultValue={""}
                                        placeholder={"이것은 알림 본문입니다.\n여러줄로 출력될 수 있을까요?\n확인해 볼 필요가 있네요."}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ps-4 col-4">알림 Extra Data (json format).<br/><span className="">optional</span></Col>
                                <Col>
                                    <textarea
                                        style={{
                                            width: "100%",
                                            height: "150px"}}
                                        id="notification-data"
                                        defaultValue={""}
                                        placeholder={"{\n  \"key\": value,\n  \"key2\": value,\n  ...\n  \"lastKey\": value\n}"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="item col-2 text-center">
                            <button
                                className="execute notosanskr-400 font-size-16"
                                type="button"
                                onClick={async (_) => {
                                    try {
                                        const to = document.getElementById("notification-to").value;
                                        const title = document.getElementById("notification-title").value;
                                        const body = document.getElementById("notification-desc").value;
                                        const data = document.getElementById("notification-data").value;
                                        console.log(`to : ${to}`);
                                        console.log(`title : ${title}`);
                                        console.log(`body : ${body}`);
                                        console.log(`data : ${data}`);
                                        await Api.sendNotification({
                                            to,
                                            title,
                                            body,
                                            data
                                        });
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        // setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                                        alert(e.message);
                                    }
                                }}
                            >
                                보내기
                            </button>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </div>
    );
}
