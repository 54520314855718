import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import Pagination from "./Pagination";
import { useIsMount } from "../../common/customHook";
import Emblem from './Emblem';
import { Row, Col } from "react-bootstrap";
import "./Comment.css";
import { AppContext } from "../../AppContext";
import { IsMobile, IsMobileOrTablet } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

/**
 * 댓글UI 컴포넌트 입니다.
 * @param {*} props properties
 *  ```
 *  {
 *      itemsCountPerPage: number // 한 페이지에 출력될 덧글 수
 *      showTotalCount: T/F // 덧글 수를 보여줄지 여부
 *  }
 *  ```
 * @returns HTMLElement
 */
export default function Comment(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({ comment: [] });
    const currentPage = useRef(1);
    const isMobile = IsMobile();
    const isMobileOrTablet = IsMobileOrTablet();
    const paginationOption = useRef({ itemsCountPerPage: props.itemsCountPerPage || 10, pageRangeDisplayed: isMobileOrTablet ? 10 : 5, totalItemsCount: 0 });
    const isMount = useIsMount();
    const context = useContext(AppContext);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { _id, getComment } = props;

    /**
     * 부모에서 받아온 comment를 받아 오는 함수 실행
     */
    const getCommentCallback  = useCallback(() => {
        const payload = {
            _id: _id,
            page: currentPage.current,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
        };

        getComment(payload)
            .then((payload) => {
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.count;
                setState((prev) => ({ ...prev, comment: payload.comment }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [isMount, _id, getComment]);

    useEffect(() => {
        getCommentCallback();
    }, [getCommentCallback]);

    useEffect(() => {
        paginationOption.current.pageRangeDisplayed = isMobileOrTablet ? 10 : 5;
        forceUpdate();
    }, [isMobileOrTablet, forceUpdate]);

    /**
     * 부모에서 받아온 comment를 추가하는 함수 실행
     */
    const addComment = () => {
        const input = document.getElementById("input");
        if (!input.value.trim()) return;

        const payload = {
            _id: props._id,
            comment: input.value.trim(),
        };

        props.addComment(payload)
            .then(() => {
                document.getElementById("input").value = "";
                getCommentCallback();
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    /**
     * 부모에서 받아온 comment를 삭제하는 함수 실행
     *
     * @param {*} e event
     */
    const removeComment = (e) => {
        const index = e.currentTarget.id;
        const payload = { _id: state.comment[index]._id };

        if (currentPage.current !== 1 && state.comment.length === 1) {
            currentPage.current--;
        }

        props.removeComment(payload)
            .then(() => {
                getCommentCallback();
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    /**
     * page를 변경 할 때 호출 되는 함수
     */
    const pageChange = useCallback((page) => {
        currentPage.current = page;
        getCommentCallback();
    }, [getCommentCallback]);

    return (
        <div className="comments-box">
            {props.showTotalCount && (
                <Row className="gx-0 top-bar">
                    <Col className="title col-auto notosanskr-500 font-size-24 notosanskr-18-500:sm lh-1.42 ls--1.2px c-333">
                        {t("86")}&nbsp;
                        <span className="notosanskr-500 font-size-16 ls-2.13 ls--0.8px c-4270e0">({paginationOption.current.totalItemsCount})</span>
                    </Col>
                </Row>
            )}
            {state.comment.length > 0
                && state.comment.map((comment, index) => (
                    <Row key={index} className="gx-0 align-items-center comment-row">
                        <Col className="col-auto avatar-wrap">
                            <Emblem
                                width={isMobileOrTablet ? "55px" : "45px"}
                                height={isMobileOrTablet ? "55px" : "45px"}
                                url={comment.profile_picture ? comment.profile_picture?.url : undefined}
                                urlClass="emblem-image"
                                char={comment.profile_picture ? undefined : comment.user_name}
                                charClass={`avatar ${isMobileOrTablet ? "avatar-54" : "avatar-45"} bg-e8ebf0 `}
                            />
                            {/* {comment.profile_picture ? (
                                  <img src={comment.profile_picture?.url} style={{ width: "30px", height: "30px" }} alt="avatar" />
                              ) : (
                                      <span
                                          style={{
                                              width: "30px",
                                              height: "30px",
                                              background: "plum",
                                              borderRadius: "50%",
                                              display: "inline-block",
                                              color: "white",
                                              objectFit: "cover",
                                              lineHeight: "30px",
                                          }}
                                      >{comment.user_name.charAt(0).toUpperCase()}</span>
                              )} */}
                        </Col>
                        <Col className="comment-body text-start">
                            <Row className="gx-0 align-items-center">
                                <Col className="comment-user-name col-auto notosanskr-500 font-size-15 c-546fa6 ls--0.75px">{comment.user_name}</Col>
                                <Col className="comment-register-date col-auto notosanskr-500 font-size-15 c-666 ls--0.75px">{new Date(comment.register_date).toLocaleDateString()}</Col>
                            </Row>
                            <Row className="gx-0">
                                <Col className="col-auto notosanskr-500 font-size-15 c-333 ls--0.75px text-break" style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</Col>
                            </Row>
                        </Col>
                        {comment.deletePermission && (
                            <Col className="col-auto text-end">
                                <button id={index} onClick={removeComment}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/Comment-ImageButton-Close.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/Comment-ImageButton-Close@2x.png 2x, ${process.env.PUBLIC_URL}/Comment-ImageButton-Close@3x.png 3x`}
                                        alt=""
                                    />
                                </button>
                            </Col>
                        )}
                    </Row>
                ))
            }

            {state.comment.length > 0 && (
                <Pagination
                    ref={pageChange}
                    page={currentPage.current}
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                />
            )}
            {context.loggedIn && (
                <Row className="gx-0 comment-input">
                    <Col className="notosanskr-400 font-size-15">
                        <textarea placeholder={t("87")} id="input" maxLength={500} />
                    </Col>
                    <Col className="col-auto">
                        <button className="c-white notosanskr-15-500 ls--0.75px" onClick={addComment}>
                            {t("88")}
                        </button>
                    </Col>
                </Row>
            )}
        </div>
    );
}
