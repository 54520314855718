import React, { useEffect, useState, useContext } from "react";
import Api from "../Api";
import { AppContext } from "../AppContext";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIsMount } from "../common/customHook";
import ActiveProjectsSection from "./Project/ActiveProjectsSection";
import ArchivedProjectsSection from "./Project/ArchivedProjectSection";
import "./Project.css";
import Emblem from "./common/Emblem";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PlannedProjectsSection from './Project/PlannedProjectsSection';
import { IsPC, IsTablet } from "./common/MediaQuery";
import { useUserinfo } from "../common/customHook";
import LoginRecommendWindow from "./common/LoginRecommendWindow";
import GroupParticipationWindow from "./common/GroupParticipationWindow";
import { POPUP as GeneralPopup } from "../common/defines";
import CustomButtonLink from "./common/CustomButtonLink";
import CanTCreateProjectWindow from "./common/CanTCreateProjectWindow";
import common from "../common/common";
import dgLogger from "../common/dgLogger";
import { Trans, useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    LoginRecommend : 1002,
    GroupParticipation: 1003,
    CanTCreateProject: 1004,
};

export default function Project() {
    const { t } = useTranslation();
    const [state, setState] = useState({
        groups: [],
        // listOfGroups: [], // data-structure for drawing on page
        popup: POPUP.None,
    });
    const [isProjectSectionLoading, setIsProjectSectionLoading] = useState(true);
    const navigate = useNavigate();
    const isPc = IsPC();
    const isTablet = IsTablet();
    const isMount = useIsMount();
    const recentReportSwiperRef = React.useRef(null);
    const { userInfo } = useUserinfo();
    const location = useLocation();

    const runningProjectAnchorRef = React.useRef(null);
    const completeProjectAnchorRef = React.useRef(null);

    useEffect(() => {
        document.getElementById("App").className = "project";
        Api.getActiveGroupListAll({ optManagers: true })
            .then((payload) => {
                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    groups: payload.filter((x) => !x.built_in),
                    // listOfGroups: payload.reduce((acc, cur, i) => {
                    //     if (i % 3 === 0) acc.push([]);
                    //     const index = Math.floor(i / 3);
                    //     acc[index].push(cur);
                    //     return acc;
                    // }, []),
                }));
            })
            .catch((e) => dgLogger.error(e)());
    }, [isMount]);

    useEffect(() => {
        //참고: https://stackoverflow.com/a/60893278

        if (isProjectSectionLoading) return; // do not proceed while loading
        if (!location.state?.hash) return;
        if (state.groups?.length == 0) return; // 상단 그룹 소개 부분이 로딩되지 않은 경우 아무것도 하지 않음

        // 진행중인 프로젝트를 표시할 경우
        if (runningProjectAnchorRef.current && location.state.hash === "#running-project") {
            // 해당 위치로 자동 스크롤
            common.scrollToElement(runningProjectAnchorRef.current, { block: "start", behavior: "smooth" });
        }

        // 완료된 프로젝트를 표시할 경우
        if (completeProjectAnchorRef.current && location.state.hash === "#complete-project") {
            // 해당 위치로 자동 스크롤
            common.scrollToElement(completeProjectAnchorRef.current, { block: "start", behavior: "smooth" });
        }
    }, [isProjectSectionLoading, state.groups, location.state]);

    // const getAvatarCircle = (group) => {
    //     if (!group) return <div />;
    //     if (group.representative_pictures && group.representative_pictures.length > 0) return <img src={`${group.representative_pictures[0].url}`} alt="Profile" className="avatar" />;
    //     return <div avatar-letter={group.name.charAt(0).toUpperCase()} />;
    // };

    const drawManager = (group) => {
        const manager = group.optManagers.find((manager) => manager._id === group.owner);

        return (
            <Row key={manager?._id || group._id} className={`gx-0 align-items-center ${isPc ? "mb-30px" : "mb-20px"}`}>
                <Col className="col-auto mb-auto avatar-wrap">
                    <Emblem
                        width={isPc ? "56px" : "50px"}
                        height={isPc ? "56px" : "50px"}
                        url={manager?.profile_picture ? manager.profile_picture?.url : undefined}
                        urlClass="emblem-image"
                        char={manager?.profile_picture ? undefined : manager?.name || "?"}
                        charClass={`avatar ${isPc ? "avatar-56" : "avatar-50"} bg-e8ebf0`}
                    />
                </Col>
                <Col className="text-start pl-12px">
                    <Row className="gx-0">
                        <Col className="col-auto nanumsquare-14b nanumsquare-12b:sm ls--0.7px c-666">
                            {t("217")}
                        </Col>
                    </Row>
                    <Row className="gx-0">
                        <Col className={"col-auto nanumsquare-21-800 nanumsquare-18-800:sm ls--0.53px text-break maxh-30px lh-sm " + (manager?.name ? "c-000" : "c-999")}>
                            {manager?.name || t("218")}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const popups = () => {
        if (state.popup === POPUP.LoginRecommend)
            return <LoginRecommendWindow onConfirm={() => { setState({ ...state, popup: POPUP.None }); navigate(`/Login/SignIn`); }} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
        if (state.popup === POPUP.GroupParticipation)
            return <GroupParticipationWindow onConfirm={() => { setState({ ...state, popup: POPUP.None }); navigate(`/Project`); }} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
        if (state.popup === POPUP.CanTCreateProject) return <CanTCreateProjectWindow GroupParticipationWindow onConfirm={() => setState({ ...state, popup: POPUP.None })} />;
    };

    return (
        <div id="project-list">
            {popups()}
            <Container>
                <span className="project-sub-title">
                    <span className="adobe-gothic-std-75 adobe-gothic-std-40:sm c-fff project">Project</span>
                    <span className="notosanskr-24b notosanskr-14b:sm c-fff">
                        {t("3")}
                    </span>
                </span>
            </Container>
            <div className="header">
                <Container>
                    <Row className="gx-0 align-items-center">
                        <Col className="title notosanskr-300 font-size-30 c-black col-auto">
                            <Trans i18nKey={"214"} components={{tag: <span className="notosanskr-600 font-size-30"></span>}} />
                        </Col>
                        <Col className="text-end">
                            <CustomButtonLink
                                className="create-project notosanskr-500 font-size-18 lh-1.67 ls--0.9px c-white"
                                onClick={(e) => {
                                    if (!userInfo) {
                                        setState({ ...state, popup: POPUP.LoginRecommend });
                                        e.preventDefault();
                                    } else if (userInfo.groups.length <= 1) {
                                        setState({ ...state, popup: POPUP.GroupParticipation });
                                        e.preventDefault();
                                    } else if (!userInfo.optPermissions.some((permission) => permission.can_create_project)) {
                                        setState({ ...state, popup: POPUP.CanTCreateProject });
                                        e.preventDefault();
                                    }
                                }}
                                to="/CreateProject"
                            >
                                <img style={{ transform: "translateY(-5%)" }} className="mr-10px" src={`${process.env.PUBLIC_URL}/168239064-d2364d4c-dda0-4630-bd28-a2cd1542ea43.png`} alt="" />
                                {t("213")}
                            </CustomButtonLink>
                        </Col>
                    </Row>

                    <Row className="gx-0">
                        <Col className="position-relative">
                            {state.groups?.length > (isPc ? 3 : isTablet ? 2 : 1) && (
                                <div className="left-chevron">
                                    <button className="swiper-button-prev" onClick={() => recentReportSwiperRef.current.swiper.slidePrev()}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/subpage_arrow_left.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/subpage_arrow_left@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/subpage_arrow_left@3x.png 3x`}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            )}
                            {/* 그룹 소개 */}
                            <Swiper
                                key={state.groups?.length}
                                slidesPerView={isPc ? 3 : isTablet ? 2 : 1}
                                // spaceBetween={20}
                                loop={state.groups?.length > (isPc ? 3 : isTablet ? 2 : 1)}
                                allowTouchMove={state.groups?.length > (isPc ? 3 : isTablet ? 2 : 1)} // 3개 초과의 경우에만 true 로 설정
                                className="list-of-group"
                                navigation={{
                                    prevEl: ".swiper-button-prev",
                                    nextEl: ".swiper-button-next",
                                }}
                                ref={recentReportSwiperRef}
                            >
                                {state.groups.map((group) => (
                                    <SwiperSlide key={group._id} className="mb-30px">
                                        <div key={group._id} className="group-card">
                                            <Row className="gx-0">
                                                <Link to={`/Group/${group._id}`} className="text-decoration-none">
                                                    <Col
                                                        className="group-image d-flex align-items-end"
                                                        style={{
                                                            backgroundImage: group.representative_picture
                                                                ? `url(${group.representative_picture?.url})`
                                                                : `url("${process.env.PUBLIC_URL}/noimg@2x.png")`,
                                                            backgroundSize: group.representative_picture ? "cover" : "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center",
                                                            border: group.representative_picture || "1px solid #ccc",
                                                        }}
                                                    >
                                                        <div
                                                            className="ms-auto px-1 py-1 me-2"
                                                            style={{
                                                                transform: "translateY(50%)",
                                                                borderRadius: "50%",
                                                                backgroundColor: "#fff",
                                                            }}
                                                        >
                                                            <Emblem
                                                                width={isPc ? "97px" : "70px"}
                                                                height={isPc ? "97px" : "70px"}
                                                                url={group.emblem_picture ? group.emblem_picture?.url : undefined}
                                                                char={group.name}
                                                                charClass={`avatar ${isPc ? "avatar-97" : "avatar-70"} bg-e8ebf0`}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Link>
                                            </Row>
                                            <div className="group-info-wrap">
                                                <Row className="gx-0">
                                                    <Col className="group-name notosanskr-700 font-size-23 text-truncate c-333 pr-110px">{common.i18nGroupName(group.name)}</Col>
                                                </Row>
                                                <Row className="gx-0">
                                                    <Col className={`group-desc notosanskr-500 font-size-18 c-666 ${isPc ? "text-truncate-3ln" : "text-truncate-2ln"}`}>
                                                        {group.about?.trim() || (
                                                            <>
                                                                {t("521")}
                                                                <br />
                                                                {t("522")}
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                            {drawManager(group)}
                                            <Row className="gx-0 align-items-center">
                                                <Col className="anchor text-end pr-8px">
                                                    <CustomButtonLink
                                                        className="notosanskr-500 font-size-14 c-white text-truncate ls-0px"
                                                        style={{
                                                            lineHeight: "16px",
                                                        }}
                                                        to={`/Group/${group._id}`}
                                                    >
                                                        {t("219")}
                                                    </CustomButtonLink>
                                                </Col>
                                            </Row>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {state.groups?.length > (isPc ? 3 : isTablet ? 2 : 1) && (
                                <div className="right-chevron">
                                    <button className="swiper-button-next" onClick={() => recentReportSwiperRef.current.swiper.slideNext()}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/subpage_arrow.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/subpage_arrow@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/subpage_arrow@3x.png 3x`}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container onLoad={() => setIsProjectSectionLoading(false)}>
                <div>
                    <ActiveProjectsSection group={null} ref={runningProjectAnchorRef} />
                </div>
                <div>
                    <PlannedProjectsSection group={null} />
                </div>
                <div>
                    <ArchivedProjectsSection group={null} ref={completeProjectAnchorRef} />
                </div>
            </Container>
        </div>
    );
}
