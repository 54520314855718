module.exports = {
    NONE: 0,

    CREATED: 1, // 요청에 성공하고 새로운 리소스를 만든 경우에 응답
    FOUND: 2, // 요청한 자원이 존재하는 경우 응답
    NOTFOUND: 3,
    UNAUTHORIZED: 4,
    BAD_REQUEST: 5, // 클라이언트 요청이 미리 정의된 파라미터 요구사항을 위반한 경우
    UPDATED: 6, // 요청한 자원이 업데이트 된 경우 응답
    NOTUPDATED: 7, // 요청한 자원이 업데이트 되지 않은 경우 응답
    REMOVED: 8, // 요청한 자원이 삭제된 경우 응답

    GENERAL_ERROR: 100,
    NOT_CREATED: 101,

    // mail verify status
    BANNED: 101,
    DUPLICATED: 102,
    VERIFY: 103,
    VERIFIED: 104,
    WRONG_CODE: 105,
};
