import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function GeneralErrorWindow(props) {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/icon_error.png`} srcSet={`${process.env.PUBLIC_URL}/icon_error@2x.png 2x, ${process.env.PUBLIC_URL}/icon_error@3x.png 3x`} alt="" />;
    const header = <div>{t("504")}</div>;
    const body = (
        <>
            <div>{t("505")}</div>
            <div className="text-break">{props.message}</div>
        </>
    );

    return (
        <Modal
            onRequestClose={props.onClick}
            onConfirm={props.onClick}
            icon={icon}
            header={header}
            body={body}
        />
    );
}
