import React, { forwardRef, useCallback } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { nanoid } from "nanoid";
import dgLogger from "../common/dgLogger";
import AWS from "aws-sdk";
import parse from 'html-react-parser';

const TinyEditor = forwardRef((props, ref) => {
    const tinyMceImagesUploadHandler = useCallback((blobInfo, progress) => {
        console.log(`blob filename ${blobInfo.filename()}, name: ${blobInfo.name()} id : ${blobInfo.id()} uri: ${blobInfo.uri()}`);
        const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);

        const S3 = new AWS.S3({
            endpoint: endpoint,
            region: process.env.REACT_APP_NAVER_CLOUD_REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
                secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY,
            },
        });

        const key = `tinymce-${nanoid(32)}-${blobInfo.filename()}`;

        return S3.putObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: key,
            ACL: "public-read",
            // ACL을 지우면 전체 공개되지 않습니다.
            Body: blobInfo.blob(),
        })
            .promise()
            .then(() => `https://kr.object.ncloudstorage.com/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${key}`)
            .catch((e) => {
                dgLogger.error(e)();
                return "Image upload failed due to a Transport error. " + e;
            });
    }, []);

    return (
        <>
            {/* {popup()} */}
            {props.viewer ?
                // Note. 하위 호환을 위해 &lt; 를 < 로 치환하는 함수 호출
                // 기존의 경우, 서버에 저장할때, 보안 문제를 회피하기 위해 < 를 &lt; 로 바꿔서 저장함.
                // 현재는 base64 로 encoding 해서 전달함. 때문에 raw 데이터를 그대로 사용함.
                parse(props.HTMLData.replace(/&lt;/gi, "<") || "")
            :
            <Editor
                apiKey={ process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(_evt, editor) => {
                    if (ref) ref.current = editor;
                }}
                onDirty={() => props.onDirty?.() }
                // Note. 하위 호환을 위해 &lt; 를 < 로 치환하는 함수 호출
                // 기존의 경우, 서버에 저장할때, 보안 문제를 회피하기 위해 < 를 &lt; 로 바꿔서 저장함.
                // 현재는 base64 로 encoding 해서 전달함. 때문에 raw 데이터를 그대로 사용함.
                initialValue={props.HTMLData.replace(/&lt;/gi, "<") || ""}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        // Core editing features
                        "anchor",
                        "autolink",
                        "charmap",
                        "codesample",
                        "emoticons",
                        "image",
                        "link",
                        "lists",
                        "media",
                        "searchreplace",
                        "table",
                        "visualblocks",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    images_upload_handler: tinyMceImagesUploadHandler,
                    automatic_uploads: false,
                    font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;' +
                    'Noto Sans="Noto Sans KR"; 나눔스퀘어=NanumSquare;' +
                    'Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;' +
                    'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif;' +
                    'Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;' +
                    'Webdings=webdings; Wingdings=wingdings,zapf dingbats;',
                    content_style: "@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');"+
                    "@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');"
                }}
            />
            }
        </>
    );
});

export default TinyEditor;
