import "./more.css";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoticeListView from "./Notice/NoticeListView";
import NoticeDetailView from "./Notice/NoticeDetailView";
import NoticeForm from "./Notice/NoticeForm";

export default function Notice() {
    const { t } = useTranslation();
    useEffect(() => {
        document.getElementById("App").className = "more";
    }, []);

    return (
        <Container>
            <div className="more-sub-title">
                <span className="adobe-gothic-std-75 adobe-gothic-std-40:sm c-fff more">Announcement</span>
                <span className="notosanskr-24b notosanskr-14b:sm c-fff">{t("916")}</span>
            </div>
            <div>
                <Routes>
                    <Route path={`/`} element={<NoticeListView />}></Route>
                    <Route path={`/page/:_no`} element={<NoticeListView />}></Route>
                    <Route path={`/new`} element={<NoticeForm />}></Route>
                    <Route path={`/edit/:_id`} element={<NoticeForm />}></Route>
                    <Route path={`/:_id`} element={<NoticeDetailView />}></Route>
                </Routes>
            </div>
        </Container>
    );
}
