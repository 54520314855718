const POPUP = { // should contain General POPUP number
    None:                         0,
    UserInfo:                     1,
    Postcode:                     8, // 주소 검색 팝업
    GroupRemovalRequest:          13,
    GroupRemovalConfirm:          14,
    GroupRemoveUserPermission:    15,
    WithdrawGroupMember:          37,
    MailTransfer:                 40,
    MailTransferCompleted:        41,
    MailTransferFailure:          42,
    WithdrawSiteMember:           43,
    UserInfoUpdate:               47,
    GeneralError:                 49,

    Request:                      50,
    RequestContent:               51,
    Requested:                    52,
    Approve:                      53,
    Approved:                     54,
    Reject:                       55,
    RejectReason:                 56,
    Rejected:                     57,
    Update:                       58,
    Remove:                       59,

    /** Custom POPUP number should start after 1000 */
};

const VIEW = {
    ListBased:                    0,
    MapBased:                     1,
    GraphBased:                   2,
    DownloadBased:                3,
};

//[[ FIXME. backend 에도 동일하게 정의되어 있음. shared lib 으로 합치면 좋을것 같음
const GROUP_STATE = {
    active: "active",
    archived: "archived",
    removed: "removed",
};

const USER_STATE = {
    active: "active", // 활동중 
    paused: "paused", // 특정 기간 이상 미 활동중 (휴면 계정)
    unregistered: "unregistered", // 탈퇴 상태
    banned: "banned", // 가입 불가 상태
};
// ]]

export {
    POPUP,
    VIEW,
    GROUP_STATE,
    USER_STATE,
};
