import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle, useRef } from "react";
import WithLocationHandle from "../hoc/WithLocationHandle";
import useLocationHook from "./LocationHook";
import LocationView from "./LocationView";
import { validateFloat } from "../common/common";
import { useTranslation } from "react-i18next";
import common from "../../../common/common";

import { POPUP as GeneralPopup } from "../../../common/defines";
import Modal from "../../common/Modal";

const POPUP = {
    ...GeneralPopup,
    ConfirmChangeGPS: 1003,
};

function ConfirmChangeGPSWindow({ onConfirm, onCancel, body }) {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/icon_error.png`} srcSet={`${process.env.PUBLIC_URL}/icon_error@2x.png 2x, ${process.env.PUBLIC_URL}/icon_error@3x.png 3x`} alt="" />;
    const header = <div>{t("936")}</div>;

    return <Modal onRequestClose={onCancel} onCancel={onCancel} onConfirm={onConfirm} icon={icon} header={header} body={body} />;
}

// 컴포넌트 이름을 Date로 하면 기존의 window Date와 충돌이 생겨 다른 컴포넌트 이름과 패턴이 다르게 명명
const Location = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { t } = useTranslation();
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return ({...fields, ...i18n[lang].fields});
        } else return fields;
    }, [fields, i18n]);
    const pasteData = useRef("");
    const [errors, setErrors] = useState({ latitude: { state: true, message: "" }, longitude: { state: true, message: "" } });
    const {
        locationValidation,
        setLocationValue,
        getLocationValue,
        getContextGPSValue,
        validateLatitude,
        validateLongitude
    } = useLocationHook(ref);
    const [gps, setGPS] = useState(getContextGPSValue());
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
    });

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const { latitude, longitude } = getLocationValue();
        const { valid, errorCollection } = locationValidation({ latitude, longitude, required });
        setErrors(errorCollection);

        return valid;
    }, [required, getLocationValue, locationValidation]);

    // gps 값이 바뀌었을 때 reference에 값 저장
    useEffect(() => {
        setLocationValue({
            latitude: Number.parseFloat(gps.latitude),
            longitude: Number.parseFloat(gps.longitude)
        });

        validation();
    }, [gps.latitude, gps.longitude, setLocationValue, validation]);

    // context에 저장 되어 있는 location이 바뀌었을 때 호출
    useEffect(() => {
        const currentGPSValue = getContextGPSValue();

        setGPS(currentGPSValue);
        // validation은 무조건 true가 되는 것이 기존 동작
        setErrors({ latitude: { state: true, message: "" }, longitude: { state: true, message: "" } });
    }, [type, getContextGPSValue, setLocationValue]);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (defaultValue) {
            setLocationValue(defaultValue);
            setGPS({ latitude: defaultValue.latitude, longitude: defaultValue.longitude });
        }
    }, [defaultValue, setLocationValue]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    const onBlurHandle = useCallback((e) => {
        validation();
    }, [validation]);

    // 입력 된 값이 바뀌었을 떄 호출 되는 handler
    // 입력 된 값이 float 형태에 맞지 않을 때 형태를 보정하여 value 수정
    // 입력 된 값이 latitude 범위를 벗어나면 이전 값으로 value 수정
    const onChangeLatitudeHandle = useCallback((e) => {
        const validFloat = validateFloat(e, pasteData.current);
        const validLatitude = validateLatitude(validFloat);

        pasteData.current = "";
        e.target.value = validLatitude ? validFloat : gps.latitude;

        if (validLatitude) {
            setGPS((prev) => ({ ...prev, latitude: validFloat }));
        }
    }, [gps.latitude, validateLatitude]);

    // 입력 된 값이 바뀌었을 떄 호출 되는 handler
    // 입력 된 값이 float 형태에 맞지 않을 때 형태를 보정하여 value 수정
    // 입력 된 값이 longitude 범위를 벗어나면 이전 값으로 value 수정
    const onChangeLongitudeHandle = useCallback((e) => {
        const validFloat = validateFloat(e, pasteData.current);
        const validLongitude = validateLongitude(validFloat);

        pasteData.current = "";
        e.target.value = validLongitude ? validFloat : gps.longitude;

        if (validLongitude) {
            setGPS((prev) => ({ ...prev, longitude: validFloat }));
        }
    }, [gps.longitude, validateLongitude]);

    // 복사 붙여 넣기로 입력 된 값이 있다면 해당 값 저장
    const onPasteHandle = useCallback((e) => {
        pasteData.current = e.clipboardData.getData("Text");
    }, []);

    const handleChangeMap = useCallback((latitude, longitude) => {
        setState({...state, popup: POPUP.ConfirmChangeGPS, popupTarget: {latitude, longitude}});
    }, [state]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const popups = useCallback(() => {
        switch (state.popup) {
            case POPUP.ConfirmChangeGPS:
                const gps = state.popupTarget;
                return (
                    <ConfirmChangeGPSWindow
                        onConfirm={() => {
                            setState({...state, popup: POPUP.None, popupTarget: undefined });
                            setGPS({ latitude: gps.latitude, longitude: gps.longitude });
                        }}
                        onCancel={() => {
                            setState({...state, popup: POPUP.None, popupTarget: undefined });
                        }}
                        body={
                            <>
                                <div className="mb-4">{t("937")}</div>
                                <div className="">{t("938")} {gps.latitude} </div>
                                <div className="">{t("939")} {gps.longitude} </div>
                            </>
                        }
                    />
                );
            case POPUP.None:
                break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    }, [state, t]);

    const viewProps = {
        title,
        description,
        type,
        latitude : gps.latitude,
        longitude : gps.longitude,
        latitudeInvalid: errors["latitude"]?.state ? "" : "invalid",
        latitudeErrorMessage: errors["latitude"]?.message,
        longitudeInvalid: errors["longitude"]?.state ? "" : "invalid",
        longitudeErrorMessage: errors["longitude"]?.message,
        onBlurHandle,
        onChangeLatitudeHandle,
        onChangeLongitudeHandle,
        onPasteHandle,
        handleChangeMap,
        popups,
    };

    return (
        <LocationView {...viewProps} />
    );
});

export default WithLocationHandle(Location);
